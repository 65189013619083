import $ from 'jquery';

export const addInputClear = (inputElement, afterClear = () => {}) => {
    const $input = $(inputElement);

    $input.parent().append(`<i class="ion-ios-close-empty" style="display: none; cursor: pointer;"></i>`);
    const $clearElement = $input.siblings('i.ion-ios-close-empty');

    $clearElement.on('click', (event) => {
        $input.val('');
        afterClear();
    });

    $input.focus((event) => {
        $clearElement.show();
    });

    $input.focusout((event) => {
        //this is to give enough time to the click event to happen
        setTimeout(() => { $clearElement.hide(); }, 250);
    });
};


