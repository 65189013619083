import $ from 'jquery';

import videojs from 'video.js';
import 'videojs-contrib-hls';

import {hasCarouselHeader, isCarouselHeader} from '../../../../../deluxe_menus/deluxe_menus/static/deluxe_menus/js/MenuUtil';

global.jQuery = $;

const HLS_STARTING_BANDWIDTH = 2736401;

var appendControls = ( container ) => {
	if ( container.find('.play-button').length == 0 ) {
		container.append('<i class="play-button video-control"></i>');
	}
};

export const getVideoPlayer = (video) => {
    let options = {};

    const doesAutoplay = video.parents('.carousel').attr('data-autoplayvideos');
    if (doesAutoplay == 'True') {
        options = {
            'controls': true,
            'fluid': true,
            'preload': "auto",
            'autoplay': false, // this is set in the Carousels.js*/
            controlBar: {
                children: {
                    'MuteToggle': {}
                }
            },
            'hls': { 'bandwidth' : HLS_STARTING_BANDWIDTH }
        };
    }
    else{
        options = {
            'controls': true,
            'autoplay': false,
            'preload': "auto",
            'fluid': true,
            controlBar: {
                children: {
                    'volumePanel': {inline: false},
                    'currentTimeDisplay': {},
                    'progressControl': {},
                    'remainingTimeDisplay': {},
                    'fullscreenToggle': {}
                }
            },
            'hls': { 'bandwidth' : HLS_STARTING_BANDWIDTH }
        };

        if(video.data('aspectratio')) {
            options.aspectRatio = video.data('aspectratio');
        }

    }
    return videojs($(video)[0], options, function(){});
};

const togglePausePlay = (player) => {
    const carouselText = $(player.el()).parents('.carousel-background').siblings('.carousel-text').find('> .center');

    if(player.paused()) {
        //player is paused, need to play video
        carouselText.hide();
        /*
        DXCAREERS-90 - IE11 videos get pixelated when 2 videos play at the same time
        so we're going to stop any other currently playing videos
        */

        $("video").each((i,e) => {
            getVideoPlayer($(e)).pause();
        });
        player.play()
    } else {
        //player is playing, need to pause it
        carouselText.show();
        player.pause();
        /*
        DXCAREERS-90 - IE11 videos get pixelated when 2 videos play at the same time
        so we're going to start any auto play videos that should be playing
        */
        $(".carousel.large[data-autoplayvideos=True] .carousel-homepage.active video").each((i,e) => {
            getVideoPlayer($(e)).play();
        });
    }
};

export const videoInit = (video) => {

    const doesAutoplay = video.parents('.carousel').attr('data-autoplayvideos');
    let player = getVideoPlayer(video);

    if (doesAutoplay != 'True') {

        appendControls(video.parent());

        let firstPlayFlag = false;
        let playButton = video.parent().parent().find('.play-button');

        playButton.click(function(event) {

            let nonAutoplayVideos = $('.carousel[data-autoplayvideos="False"]').find('.video-js');
            let currentVideo = $(this).parent();

            firstPlayFlag = true;
            playerControls.removeClass('hidden');

            nonAutoplayVideos.each(function(index, el) {
                $(el).removeClass('currently-playing');
            });

            currentVideo.addClass('currently-playing');
            togglePausePlay(player);
        });

        $('.video-js').unbind('keypress.video').bind('keypress.video', function (e) {
            if (e.keyCode === 0 || e.keyCode === 32) {
                e.preventDefault()

                let currentVideo = videojs($('.currently-playing > video')[0]);
                togglePausePlay(currentVideo);
            }
        })

        let playerControls = $(video).parent().find('.vjs-control-bar');

        if (!firstPlayFlag) {
            playerControls.addClass('hidden');
        }

        player.on('fullscreenchange', (event) => {

            const scrollSoVideoIsCentered = (event) => {
                const $eventTargetParent = $(event.target).parent();
                //if the video is at the top of the screen then we're going to put the user
                //at the top, otherwise we're going to put the video in the middle of the page
                const targetAbsoluteMiddle = $eventTargetParent.offset().top + $eventTargetParent.height()/2;
                const halfWindowHeight = $(window).height()/2;
                const y = (targetAbsoluteMiddle - halfWindowHeight) > 0 ? targetAbsoluteMiddle - halfWindowHeight : 0;
                window.scrollTo(0, y);
            };

            const isFullScreen = player.isFullscreen();

            if(hasCarouselHeader()) {
                if(isFullScreen) {
                    //we want to set the visibility of the top carousel to 0 because the image gets flased in IE11/Edge
                    //only if we're not playing the first video.
                    const $current = $(event.target).parents(".carousel.large");
                    const $first   = $(".carousel.large").first();


                    if(!$current.is($first)) {
                        $(".carousel.large").first().css('visibility', 'hidden');
                    }

                } else {
                    //remove visibility property
                    setTimeout(
                        () => {
                            $(".carousel.large").first().css('visibility', 'visible');
                            scrollSoVideoIsCentered(event);
                        }, 500);

                }
            } else if(!player.isFullscreen()) {
                scrollSoVideoIsCentered(event);
            }
        });

    }

    //if the video is a header video then we need to fix the height of the video itself in case hls switches
    //the bandwidth
    const carouselParent = video.parents('.carousel')
    const isHeader = carouselParent.length > 0 ? isCarouselHeader(carouselParent) : false;
    if(isHeader) {
        let ratio = video.height() / video.width();
        let originalVideoWidth = video.width();
        let originalVideoHeight = video.height();

        const setVideoHeightAndWidth = () => {

            const parentWidth = $(video).parent().width();
            const parentHeight = $(video).parent().height();
            //console.log(`parentWidth: ${parentWidth}, originalVideoWidth: ${originalVideoWidth}, parentHeight: ${parentHeight}, originalVideoHeight: ${originalVideoHeight}`);

            if( parentWidth > originalVideoWidth || parentHeight > originalVideoHeight) {
                //so the lower bound must be set to at least the width or height of the screen.
                //could be both at the same time, so we need to calculate the new values,
                //then we need to calculate the corresponding other value to compare them
                let tmpLargeWindowWidthHeight = ratio * parentWidth;
                let tmpLargeWindowHeightWidth = parentHeight / ratio;

                if(tmpLargeWindowWidthHeight > parentHeight) {
                    //console.log("setting height to ratio value");
                    video.css({height: tmpLargeWindowWidthHeight, width: parentWidth});
                } else {
                    //console.log("setting width to ratio value");
                    video.css({height: parentHeight, width: tmpLargeWindowHeightWidth});
                }

            } else {
                //console.log("setting to original video height, original video width");
                video.css({height: originalVideoHeight, originalVideoWidth});
            }

        };

        let videoStarted = false;
        player.on('play', (event) => {
            setTimeout( () => {
                ratio = video.height() / video.width();
                //ratio = 0.5620111731843576;
                originalVideoWidth = video.width();
                //why is the original video height wrong.
                originalVideoHeight = video.height();
                //console.log(`set from play event!! originalVideoWidth: ${originalVideoWidth}, originalVideoHeight: ${originalVideoHeight}, ratio: ${ratio}`);
                setVideoHeightAndWidth();
                videoStarted = true;
            }, 2500) /* this is used because autoplay videos don't initialize the video.height and width immediately */

        });

        $(window).on('resize', () => {
            if(videoStarted) {
                //console.log(`video width: ${video.width()}, window width: $(window).width()`);
                setVideoHeightAndWidth();
            }
        });
    }

    let controlButtons = $(this).find('.play-button, .vjs-control-bar');
    let i = null;

    video.parent().parent().mousemove(function() {
        clearTimeout(i);
        controlButtons.removeClass('hide');

        i = setTimeout(function () {
            controlButtons.addClass('hide');
        }, 3000);
    }).mouseleave(function() {
        clearTimeout(i);
        controlButtons.removeClass('hide');
    });

    return player;

}
