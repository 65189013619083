import $ from 'jquery';
require("babel-polyfill");
require('isomorphic-fetch'); //this will provide a polyfill for fetch
require("picturefill");

//import {post} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/DeluxeAJAX';

import {Carousels}  from '../../../../methodstudios_carousels/static/methodstudios_carousels/js/Carousels';
import {VideoPublisher}  from '../../../../methodstudios_carousels/static/methodstudios_carousels/js/VideoPublisher';

import {Careers}  from '../../../../methodstudios_careers/static/methodstudios_careers/js/Careers';
import {News}  from '../../../../methodstudios_news/static/methodstudios_news/js/News';
import {Menu}  from '../../../../methodstudios_menus/static/methodstudios_menus/js/Menu';

import {SearchGrid} from '../../../../methodstudios_features/static/methodstudios_features/js/SearchGrid';
import {SearchOverlay}  from '../../../../methodstudios_menus/static/methodstudios_menus/js/SearchOverlay';
import {AboutCarousel} from '../../../../methodstudios_features/static/methodstudios_features/js/AboutCarousel';
import {AboutNews} from '../../../../methodstudios_features/static/methodstudios_features/js/AboutNews';
import {WorkGrid} from '../../../../methodstudios_features/static/methodstudios_features/js/WorkGrid';
import {ProductionGrid} from '../../../../methodstudios_features/static/methodstudios_features/js/ProductionGrid';
import {TwoTextColumn} from '../../../../methodstudios_features/static/methodstudios_features/js/TwoTextColumn';
import {SocialShare} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/SocialShare';
//import {CookieBanner} from '../../../../bydeluxe/deluxe_cookie_banner/cookie_banner/static/cookie_banner/js/CookieBanner';

import {AnchorTag} from '../../../../methodstudios_menus/static/methodstudios_menus/js/AnchorTag';

import {HomepageFullscreenCarousel} from './HomepageFullscreenCarousel';

import {CareersAutocomplete} from '../../../../bydeluxe/deluxe_careers/deluxe_careers/static/deluxe_careers/js/CareersAutocomplete';
import {CareersSearch} from '../../../../bydeluxe/deluxe_careers/deluxe_careers/static/deluxe_careers/js/CareersSearch';

import {CareerStudioCarousel} from '../../../../bydeluxe/deluxe_careers/deluxe_careers/static/deluxe_careers/js/CareerStudioCarousel';

const wrapTryCatch = ( ClassRef ) => {
	try{
		new ClassRef();
	}
	catch(err){
		console.error(err);
	}
}

wrapTryCatch( Carousels );
wrapTryCatch( VideoPublisher );
wrapTryCatch( Careers );
wrapTryCatch( News );
wrapTryCatch( Menu );
wrapTryCatch( SearchGrid );
wrapTryCatch( SearchOverlay );
wrapTryCatch( AboutCarousel );
wrapTryCatch( AboutNews );
wrapTryCatch( WorkGrid );
wrapTryCatch( TwoTextColumn );
wrapTryCatch( ProductionGrid );
wrapTryCatch( SocialShare );
//wrapTryCatch( CookieBanner );
wrapTryCatch( AnchorTag );
wrapTryCatch( HomepageFullscreenCarousel );
wrapTryCatch( CareersAutocomplete );
wrapTryCatch( CareersSearch );
wrapTryCatch( CareerStudioCarousel );

try {
  const ga_id = global.google_analytics_id;
  if(ga_id) {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      const host = window.location.hostname;

      ga('create', ga_id, 'auto');
      ga('set', 'dimension1', host);
      ga('send', 'pageview');
  }
} catch(err){
  console.error(err);
}
