import $ from 'jquery';
global.jQuery = $;
require('../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/vendors/deluxe-jquery-pjax');

const Packery = require('packery');

import {searchAll} from '../../../../bydeluxe/deluxe_search/search/static/search/js/SearchAPI';
import {resetSearchResults, setupAppendResults, NO_SEARCH_RESULTS_WRAPPER_SELECTOR} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/SearchUtil';
import {checkedFilters, checkedFiltersNames, getAllFilterElements, resetFilters, loadFilterStateFromQueryString, isDefaultFilterState, updatePageURLs} from '../../../../bydeluxe/deluxe_search/search/static/search/js/SearchFilter';
import {getCurrentQueryStringParams, parseUrlForQueryStringParams} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/URLUtil';
import {closeSecondaryMenu} from '../../../../bydeluxe/deluxe_menus/deluxe_menus/static/deluxe_menus/js/Menu';
// import {gridSetup} from '../../../../bydeluxe/deluxe_features/features/static/features/js/Grid';
import {hideSecondaryNavSearchForm} from '../../../../bydeluxe/deluxe_menus/deluxe_menus/static/deluxe_menus/js/Menu';
import {updatePermalinkCloseUrl} from '../../../../bydeluxe/deluxe_features/features/static/features/js/PermalinkOverlay';

/*
Search parameters for load more button
*/
const SEARCH_RESULTS_SELECTOR = '.search-results';
const LOAD_MORE_BUTTON_SELECTOR = `${SEARCH_RESULTS_SELECTOR} button.load-more`;
const RESULTS_WRAPPER_SELECTOR = `${SEARCH_RESULTS_SELECTOR} .grid-container .grid`;
const COUNT_SELECTOR = `${RESULTS_WRAPPER_SELECTOR} .grid-item`;

const updateSecondaryMenuShowFilters = () => {
    $('.secondary-menu .jump-to').each((i,e) => { $(e).hide(); });
    const filters = checkedFilters(".card-filter-menu");

    let innerHTML = '<ul>';
    for (var key in filters) {
        const category = filters[key];
        const index = category.findIndex((e) => {
            console.log(e);
            return e.dataset.setbydefault == 'true';
        });


        if(index < 0) {
            //now that we have the category we can put that name below.
            console.log(category);
            const title = $(category).first().siblings('h4').first().text();
            console.log(title);

            let filterHtml = '';
            category.forEach((e) => {
                console.log(`e: ${e.value}`);
                console.log(e);
                filterHtml += `${e.value}, `;
            });
            filterHtml = filterHtml.substr(0, filterHtml.length - 2);
            console.log(filterHtml);
            innerHTML += `<li><span class='title'>${title}</span>&nbsp;${filterHtml}</li>`;
        }
    }
    //need the search term as well.
    const search_term = getCurrentQueryStringParams().search_term;

    if(search_term) {
        $('.secondary-menu > .search-term').each((i,e) => {
            const search_term_title = $(e).html();
            innerHTML += `<li>${search_term_title}&nbsp;${search_term}</li>`;
        });
    }
    innerHTML += '</ul>';
    console.log(`innerHTML: ${innerHTML}`);
    $('.chosen-filters .selected-filters').each((i,cf) => {
        cf.innerHTML = innerHTML;
    });
    $('.chosen-filters').show().removeClass('hide');
};

export const addHits = (gridSelector, hits) => {

    $(gridSelector).each((i, gridElement) => {
        hits.forEach((hit) => { $(gridElement).append(hit.html); }); //we do this first just incase there are no elements

        // const packeries = gridSetup(gridSelector, false);

    });
};


const appendResults = (wrapper, response) => {
    if(!response.total) {
        $(SEARCH_RESULTS_SELECTOR).find(NO_SEARCH_RESULTS_WRAPPER_SELECTOR).removeClass("hide");
        $(SEARCH_RESULTS_SELECTOR).find(".load-more-container").addClass("hide");
    } else {
        $(SEARCH_RESULTS_SELECTOR).find(NO_SEARCH_RESULTS_WRAPPER_SELECTOR).addClass("hide");
        $(SEARCH_RESULTS_SELECTOR).find(".load-more-container").removeClass("hide");
        addHits(wrapper, response.hits);
    }
};

const updateView = () => {
    closeSecondaryMenu();
    $('body').addClass('is-pjax-active');
    $('#pjax-container').show();
    $('.page-content > :not(nav)').hide();
    updateSecondaryMenuShowFilters();
    //close search form
    hideSecondaryNavSearchForm();

    const getSearchParams = () => {
        const params = checkedFiltersNames(".card-filter-menu");
        params.size = getCurrentQueryStringParams().size;
        return params;
    };

    setupAppendResults(
            RESULTS_WRAPPER_SELECTOR,
            LOAD_MORE_BUTTON_SELECTOR,
            COUNT_SELECTOR,
            searchAll,
            getSearchParams,
            appendResults
    );
    resetSearchResults(RESULTS_WRAPPER_SELECTOR, LOAD_MORE_BUTTON_SELECTOR);
};

const loadPageState = (changed = false) => {
    //first we grab the page load filters
    const qsp = getCurrentQueryStringParams();
    //if we have a search_term or we have a filter element that is not a default then we should show the page.
    loadFilterStateFromQueryString(qsp, ".card-filter-menu");
    updatePermalinkCloseUrl();

    const filtersState = isDefaultFilterState(".card-filter-menu");
    const searchQuery = qsp.search_term;
    if(searchQuery || !filtersState) {
      updateView();
    }
    else if (searchQuery !== undefined && filtersState) {
      updateView();
    }
    else if (searchQuery === undefined) {
      resetView();
    }
    else {
      resetView();
    }
};

const resetView = () => {
    closeSecondaryMenu();
    $('body').removeClass('is-pjax-active');
    $('#pjax-container').hide();
    $('.page-content > :not(nav):not(.feature-video-overlay-wrapper)').each((i,e) => { $(e).show(); });
    $('.secondary-menu .jump-to').each((i,e) => { $(e).show(); });
    $('.secondary-menu .chosen-filters').each((i,e) => {$(e).hide();});
    resetFilters(".card-filter-menu");
};

const resetViewFilters = () => {

  resetFilters(".card-filter-menu");
  updatePageURLs('.card-filter-menu .update-page > a');

  const formParent = $('#secondary-nav-find-container');
  const formInput = $(formParent).find('input[type="text"]');
  const form = $(formParent).find('form');

  formInput.val('');
  form.submit();
};

const updateClearFiltersUrl = () => {
  $(".clear-selected-filters > a").each((i, e) => {

      $(e).click( (event) => {
        event.preventDefault();

        resetViewFilters();

      })
  });
};


export class SearchResults {

    constructor() {
        $('body.content').each((i,e) => {
            const pjaxContainerId = 'pjax-container';
            $(document).pjax('a[data-pjax]', `#${pjaxContainerId}`);
            $(document).on('submit', 'form[data-pjax]', function(event) {
              $.pjax.submit(event, '#pjax-container')
            });
            $(document).on('pjax:end', (event) => {
                //need to check that the pjax event is for the search results container.
                if(event.target.id && event.target.id == pjaxContainerId) {
                    loadPageState(true);
                }
            });
            $('.secondary-menu').each((i,e) => {
                loadPageState();
            });

            updateClearFiltersUrl();
        });

        $('.card-filter-menu .close-menu a').click((event) => {
          event.preventDefault();

          loadFilterStateFromQueryString(getCurrentQueryStringParams(), ".card-filter-menu");
        });
    }

};
