import { fetchGet, pick } from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/BaseAPIUtil';

function get(url, data) {
    //here we should filter all the unneeded parameters out.
    const params = pick(data, 'page', 'size', 'content', 'services',
        'studios', 'locations', 'artists', 'search_term',
        'date_range', 'sort', 'exclude', 'division_ids');
        
    return fetchGet(url, params);
}

export function searchAll(data) {
    return get('/api/search', data);
}

export function searchNews(data) {
    return get('/api/news/search', data);
}

export function searchCaseStudies(data) {
    return get('/api/case-studies/search', data);
}