import $ from 'jquery';

import {getCurrentQueryStringParams, generateUrl} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/URLUtil';

import {updatePageURLs} from '../../../../../deluxe_search/search/static/search/js/SearchFilter';

const MAIN_NAV_WRAPPER = 'main-nav-wrapper';
const GLOBAL_SEARCH_OVERLAY_ID = 'global-search-overlay';
const MAIN_MENU_OVERLAY = '.main-menu-overlay';
const MAIN_MENU_OVERLAY_ITEM = '.main-menu-overlay .overlay-menu > .menu-item > a';

export const SECONDARY_NAV_FIND_FORM_CONTAINER_ID = 'secondary-nav-find-container';
const SECONDARY_NAV_FIND_BUTTON_ID = 'secondary-nav-find-button';
const SECONDARY_NAV_FIND_CONTAINER = '.secondary-menu .right.search';
const SECONDARY_NAV_FIND_CLOSE_BUTTON = 'secondary-nav-find-close-button';
const SECONDARY_NAV_CHOSEN_FILTERS = `.secondary-menu .chosen-filters`;

const PARENT_MENU_LINK_SELECTOR = ".parent-menu-link";

const FIXED_VIEW = 'fixed-view';

const setFixedView = () => {
  $('html, body').addClass(FIXED_VIEW);
}

const resetFixedView = () => {
  $('html, body').removeClass(FIXED_VIEW);
}

export const closeSecondaryMenu = () => {
    resetFixedView();
    $('.secondary-menu .overlay').each((i,e) => { $(e).hide(); });
};

const openGlobalSearchOverlay = (event) => {
    event.preventDefault();

    $(event.target).parents('.container').first().hide();
    $(`#${GLOBAL_SEARCH_OVERLAY_ID}`).each((i,e) => {
        setFixedView();
        $(e).show();
        $(e).find('form input').first().focus();
    });
};

const closeGlobalSearchOverlay = (event) => {
    event.preventDefault();
    $(`#${GLOBAL_SEARCH_OVERLAY_ID}`).each((i,e) => {
        $(e).hide();
        resetFixedView();
    });
    $(`.${MAIN_NAV_WRAPPER} > .container`).show();
};

const showSecondaryNavSearchForm = () => {
    $(`#${SECONDARY_NAV_FIND_BUTTON_ID}`).hide();
    $('.secondary-menu > ul > li:not(.search)').addClass("find-hidden-xs");
    $(`${SECONDARY_NAV_FIND_CONTAINER}`).addClass('activated');
    $(`${SECONDARY_NAV_CHOSEN_FILTERS}`).addClass('activated');
    $(`#${SECONDARY_NAV_FIND_FORM_CONTAINER_ID}`).each((i,e) => {
        $(e).show();
        updatePageURLs();
    });
};

export const hideSecondaryNavSearchForm = () => {
    $(`#${SECONDARY_NAV_FIND_FORM_CONTAINER_ID}`).hide();
    $('.secondary-menu > ul > li:not(.search)').removeClass("find-hidden-xs");
    $(`${SECONDARY_NAV_FIND_CONTAINER}`).removeClass('activated');
    $(`${SECONDARY_NAV_CHOSEN_FILTERS}`).removeClass('activated');
    $(`#${SECONDARY_NAV_FIND_BUTTON_ID}`).show();
};

const checkIfParentHasGrandChildren = (container, selector) => {

  $(container).each((i, e) => {
    let grandChildren = $(container).find(selector);
    if (grandChildren.length) {
      $(grandChildren).parent().addClass('has-submenu');
    }
  });
}

const checkMenuItemHeight = (selector) => {

  $(selector).each((i, e) => {

    const submenu = $(e).parent().find('> ul');
    const submenuHeight = $(submenu).height();
    const submenuItems = $(submenu).find('.has-submenu');

    if (submenuHeight > 435 && !submenuItems.length) {
      $(submenu).find('> li').addClass('is-1-of-2');
    }
  });
}

const openOverlayMenu = () => {

  $(MAIN_MENU_OVERLAY).each(( i, e ) => {
    $(e).removeClass('hide');

    checkMenuItemHeight(MAIN_MENU_OVERLAY_ITEM);
  });
}

const closeOverlayMenu = () => {

  $(MAIN_MENU_OVERLAY).each(( i, e ) => {
    $(e).addClass('hide');
    $('.main-nav-wrapper').find('li').removeClass('is-selected');
  });
}

const setActiveClassAfterPageLoad = () => {

  const overlayMenu = $(MAIN_MENU_OVERLAY).find('.overlay-menu');
  const currentPage = $(overlayMenu).find('.current-page');
  const currentPageParent = $(currentPage).parents('.overlay-menu > li');

  $(currentPageParent).addClass('is-selected');
}

const setActiveClass = (container, selector) => {
  const parent = $(selector).parent();
  const siblings = $(parent).siblings();
  const html = $(selector).html();

  $(MAIN_MENU_OVERLAY).find('a').each( ( i, e ) => {
    let html2 = $(e).html();
    if (html === html2) {
      $(e).parents('.overlay-menu > li').addClass('is-selected');
    }
  });

  siblings.removeClass('is-selected');
  parent.addClass('is-selected');
}

const checkDeliveryMenu = ( selector ) => {

  if ( $(selector).is(':visible') ) {
    const deliveryMenuList = $(selector).next('ul');
    if ( deliveryMenuList.find('li').length === 1 ) {
      selector.addClass('has-no-menu');
      deliveryMenuList.addClass('has-no-menu-item');
    }
  }

}

const checkMenuWidth = (selector) => {

  const parentWidth = $(selector).innerWidth();
  const logoWidth = $('.menu-logo').innerWidth();
  const menuWidth = $(selector).find('ul').innerWidth();
  const parentSpaceForMenu = parentWidth - logoWidth - 15;

  $(selector).find('ul').show();

  if ( menuWidth > parentSpaceForMenu ) {
    $( selector ).addClass('is-dropdown-menu');
  }

};

export class Menu {

    constructor() {
        setActiveClassAfterPageLoad();
        checkIfParentHasGrandChildren('.overlay-menu > li > ul > li', '> ul');

        checkMenuWidth( $('.delivery-inner-menu') );
        $( window ).on( 'resize', () => {
          checkDeliveryMenu( deliveryMenu );
          checkMenuWidth( $('.delivery-inner-menu') );
        });

        //there are two times we open the menu, when one of the links is hit directly or when a special button is hit
        $('.'+MAIN_NAV_WRAPPER).find('.main-nav > li.menu-item > a').each( (index, element) => {

          $(element).click( event => {

            event.preventDefault();

            setActiveClass($('.'+MAIN_NAV_WRAPPER), element);
            openOverlayMenu();
            setFixedView();
          });
        });

        $('.'+MAIN_NAV_WRAPPER).find('.main-menu a').each( (index, element) => {

          $(element).click( event => {
            event.preventDefault();

            setActiveClassAfterPageLoad();
            openOverlayMenu();
            setFixedView();
          });
        });

        $('.'+MAIN_NAV_WRAPPER).find('.show-menu a').each( (index, element) => {

          $(element).click( event => {
            event.preventDefault();

            openOverlayMenu();
            setFixedView();
          });
        });

        $(MAIN_MENU_OVERLAY).find('.overlay-close').click((event) => {

          event.preventDefault();

          resetFixedView();
          closeOverlayMenu();
        });

        $(MAIN_MENU_OVERLAY).find('.overlay-menu > li > a').click( (event)=> {
          const target = $(event.currentTarget);
          const targetUrl = target.attr('href');
          const targetParent = target.parent();

          if (targetUrl === '#') {
            event.preventDefault();

            targetParent.siblings().removeClass('is-selected');
            targetParent.addClass('is-selected');

            checkMenuItemHeight(event.currentTarget);
          }
        });

        $('.secondary-menu li.page-filter a').each((index, element) => {
            $(element).click((event) => {
                event.preventDefault();

                //need to show secondary menu add position it.
                const secondaryMenu = $(event.target).parents('.secondary-menu');
                const overlay = secondaryMenu.find('> div.overlay').first();

                setFixedView();
                overlay.show();
            });
        });
        $('.secondary-menu .card-filter-menu .close-menu a').each((index, element) => {
            $(element).click((event) => {
                event.preventDefault();
                closeSecondaryMenu();
            });
        });

        $(`.${MAIN_NAV_WRAPPER} .search`).on('click', openGlobalSearchOverlay);
        $(`.${MAIN_NAV_WRAPPER} .search-close`).on('click', closeGlobalSearchOverlay);

        $(`#${GLOBAL_SEARCH_OVERLAY_ID} .body > button`).on('click', (event) => {
            $(`#${GLOBAL_SEARCH_OVERLAY_ID} form`).each((i,e) => {
                $(e).submit();
            });
        });

        $(`#${SECONDARY_NAV_FIND_BUTTON_ID}`).on('click', (event)=> {

          event.preventDefault();

          showSecondaryNavSearchForm();
        });
        $(`#${SECONDARY_NAV_FIND_CLOSE_BUTTON}`).on('click', hideSecondaryNavSearchForm)

        // Delivery secondary menu

        const deliveryMenu = $('.delivery-inner-menu .menu-expand');
        checkDeliveryMenu( deliveryMenu );

        deliveryMenu.click(function(event){
            $(this).toggleClass('active');
        });

        $(document).click(function(e) {
            if ( $(e.target).closest('.delivery-inner-menu .menu-expand').length === 0 ) {
                deliveryMenu.removeClass('active');
            }
        });
    }

}
