import qs from 'qs';
import $ from 'jquery';
require('jquery.scrollto');

const scrollToAnchorTag = (hash) => {
    //need to get the height of the persistant nav
    const persistentNavHeight = $(".main-menu-wrapper").height();
    //need to scroll to location minus persistant nav height
    const position = $(hash).offset().top - persistentNavHeight;
    $(window).scrollTo(position, '500', {'interrupt' : false});
};

export class AnchorTag {
    constructor() {
        //first we check if there is an anchor tag in the URL
        const hash = window.location.hash;
        if(hash) {
            //need to allow enough time for any ajax to load.
            [200].forEach((interval) => {
                setTimeout(() => {
                    scrollToAnchorTag(hash);
                }, interval);

            });

        }
    }
}