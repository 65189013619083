import $ from 'jquery';
import {slick} from 'slick-carousel';

export class CareerStudioCarousel {
    constructor() {

        $('.career-studios-carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay: false,
            useTransform: false,
            variableWidth: false,
            arrows: true,
            prevArrow: '<i class="ion-chevron-left slick-prev" data-pack="default" data-tags=""></i>',
            nextArrow: '<i class="ion-chevron-right slick-next" data-pack="default" data-tags=""></i>',
        });



    }
}
