import $ from 'jquery';
/* wasnt' working, videojs worked but videojs-contrib-hls did not, so put js files in the footer and used the global.videojs
import videojs from 'video.js'
global.videojs = videojs;
require('videojs-contrib-hls');
*/

import {sendVideoPercentWatchedAnalytic} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/AnalyticsUtil';
import {isInternetExplorer} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/BrowserUtil';


const videojs = global.videojs;

const videoPercentageBucketSize = 4;

const videoContainer = () => { return $('.video-container'); };

const getVideo = () => {
    return videoContainer().find('.video-js');
};

export const disposeOfPlayer = () => {
    getVideo().each((i,e) => {
        videojs(e).dispose();
    });
};



export const pauseVideo = (e) => {
    videojs(e).pause();
};

const videoEvent = (options) => {

    getVideo().find('video').each((i,e) => {
        const videoName = e.dataset.name;
        const {action, percentWatched} = options;
        console.log(`action: ${action}, percentWatched: ${percentWatched}`);
        sendVideoPercentWatchedAnalytic(videoName, percentWatched,
            { hitType: 'event', eventCategory: 'Video', eventAction: action, eventLabel: videoName });
    });
};

const createAndAppendVideoElement = (parentDiv) => {
    const template = (data) => {
        const {poster, name, hls_url, mp4_url, no_js_message} = data;

        let source = `<source src="${mp4_url}" type="video/mp4">`;
        if(!isInternetExplorer()) {
          source = `<source src="${hls_url}" type="application/x-mpegURL">` + source;
        }
        return `<video
                id="video-permalink-player"
                class="video-js vjs-fluid"
                controls
                preload="auto"
                poster="${poster}"
                width="734px"
                height="413px"
                data-name="${name}"
            >
              ${source}
              <p class="vjs-no-js">
                ${no_js_message};
                <a href="http://videojs.com/html5-video-support/" target="_blank">
                  supports HTML5 video
                </a>
              </p>
            </video>`;

    };
    $(parentDiv).append(template(parentDiv.dataset));
};

const setVideoEvents = (video) => {
    video.on('loadstart', (event) => {
      videoEvent({"action": 'embeds'});
    });
    video.on('play', (event) => {
      videoEvent({"action":'play'});
    });
    video.on('pause', (event) => {
      videoEvent({"action":'pause'});
    });
    video.on('ended', (event) => {
      videoEvent({"action" : 'ended'});
    });
    video.on('timeupdate', (event) => {
      console.log("timeupdate");
      console.log(event);
      $(event.target).find('video').each((i,e) => {
        console.log(`e.currentTime: ${e.currentTime}`);
        console.log(`e.duration: ${e.duration}`);
        const previous = !e.dataset.previousPercentWatched || isNaN(e.dataset.previousPercentWatched) ? 0 : parseInt(e.dataset.previousPercentWatched);
        const percentage = Math.floor((e.currentTime/e.duration)*100);
        console.log(`previous: ${previous + videoPercentageBucketSize}, percentage: ${percentage}`);
        if(previous + videoPercentageBucketSize < percentage) {
            console.log("sending event because previous + videoPercentageBucketSize < percentage");
            e.dataset.previousPercentWatched = percentage;
            videoEvent({ "action": 'percent_watched', "percentWatched": percentage});
        }
      });
    });
    video.on('error', (event) => {
      console.log("error");
      console.log(event);
      videoEvent({ "action": 'error' });
    });
};

export class Video {
    constructor() {
        videoContainer().each((i,container) => {
            //global.videojs('video-permalink-player', { "inactivityTimeout": 0 });

            //https://html.spec.whatwg.org/multipage/embedded-content.html#mediaevents
            /*
            Content	Any video or audio content object with a unique Media ID
            Embeds	Players that have loaded on a webpage
            Plays	Video starts across all embedded players
            Completes	Videos watched to completion
            Daily Unique Viewers	Unique users who have played at least one video
            Plays per Unique	The number of plays divided by the number of unique viewers on specific day
            Complete Rate	The number of plays divided by the number of completes, as a percentage
            Time Watched	Total time watched across all your players
            Engagement	Use this data to track the performance of your video (where do viewers drop off? which sections do they replay?)
            Ad Impressions	Ad start events across all embedded players
            */
            createAndAppendVideoElement(container);
            getVideo().each((i, videoElement) => {
                console.log(`videoElement: ${videoElement}`);
                const video = videojs(videoElement, { "inactivityTimeout": 0 });
                setVideoEvents(video);
            });



        });
    }
}
