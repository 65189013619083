
const isGAEnabled = () => {
    return typeof window['ga'] != 'undefined';
};

export const sendAnalytic = (data) => {
    if(isGAEnabled()) {
        ga('send', data);
    }
};

export const sendVideoPercentWatchedAnalytic = (videoName, percentWatched, data) => {
    if(isGAEnabled()) {
        ga('set', 'dimension1', videoName);
        if(percentWatched) {
            ga('set', 'dimension2', percentWatched);
        }
        ga('send', data);
    }
};

