import $ from 'jquery';

const ACTIVE = 'active';

import {searchAll} from './SearchAPI';

import {generateUrl, getCurrentQueryStringParams} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/URLUtil';

import {SECONDARY_NAV_FIND_FORM_CONTAINER_ID} from '../../../../../deluxe_menus/deluxe_menus/static/deluxe_menus/js/Menu';

export const getAllFilterElements = () => {
    const filters = {};
    $('.card-filter-menu .filter-wrapper input[type="checkbox"]').each((i, e) => {
        const category = e.dataset.filtertype;
        if(filters[category]) {
            filters[category].push(e);
        }
        else {
            filters[category] = [e];
        }
    });
    return filters;
};

export const checkedFilters = (searchResultsSelector) => {
    const data = {};
    $(`${searchResultsSelector} input:checked`).each((j, jElement) => {
        const filterValue = jElement;
        if(!jElement.id.startsWith('all')) { //want to exlucde the 'all' checkbox
            if(data[jElement.dataset.filtertype])
                data[jElement.dataset.filtertype].push(filterValue);
            else {
                data[jElement.dataset.filtertype] = [filterValue];
            }
        }
    });
    return data;
};

export const toggleShowAll = (event, searchResultsSelector) => {
    const checkSelector = `${searchResultsSelector} input[data-filtertype='${event.target.dataset.filtertype}']:checked`
    if(event.target.id.startsWith('all') || $(checkSelector).length == 0) {
        $(`${checkSelector}:not(#${event.target.id})`).each((i,e) => { $(e).prop('checked', false)});
        $(`#all-${event.target.dataset.filtertype}`).each((i,e) => { console.log(e);$(e).prop('checked', true) });
    } else {
        $(`#all-${event.target.dataset.filtertype}`).each((i,e) => { $(e).prop('checked', false) });
    }
};

export const checkedFiltersNames = (searchResultsSelector) => {

    const data = {};
    const filters = checkedFilters(searchResultsSelector);
    for (var key in filters) {
        data[key] = filters[key].map((e) => { return e.name; });
    }

    data.search_term = getCurrentQueryStringParams().search_term;

    return data;
};

export const resetFilters = (selector) => {
    const filters = checkedFilters(selector);
    Object.keys(filters).forEach((key,index) => {
        filters[key].forEach((e) => {
            if(e.dataset.setbydefault != 'true') {
                $(e).prop('checked', false);
                const event = {'target' : e};
                toggleShowAll(event, selector);
            }
        });
    });
};

export const updatePageURLs = () => {
    const data = checkedFiltersNames(".card-filter-menu");
    updateTheUpdatePageURL(data);
};

const updateTheUpdatePageURL = (checkedFiltersData) => {

    const url = generateUrl(location.pathname, checkedFiltersData);
    $('.card-filter-menu .update-page > a').each((index, element) => {
        if (url.indexOf('search_term') == -1) {
          $(element).attr('href', url + '&search_term=');
        }
        else {
          $(element).attr('href', url);
        }
    });
    //also need to update the search box.
    $(`#${SECONDARY_NAV_FIND_FORM_CONTAINER_ID} form`).each((i, e) => {
        $(e).find("input[type=hidden]").remove();
        /* not the prettiest but we need to turn all these parameters into hidden form inputs */
        $(e).find("input").val(checkedFiltersData.search_term);
        $.each(checkedFiltersData, (i,v) => {
            if(i != 'search_term') {
                const input = `<input type='hidden' name='${i}' value='${v}' />`;
                $(e).append(input);
            }
        });
    });

};

export const loadFilterStateFromQueryString = (qsp, selector) => {

    resetFilters(selector);
    const allFilters = getAllFilterElements();

    for (var key in qsp) {
        const category = allFilters[key];
        const qsCategory = Array.isArray(qsp[key]) ? qsp[key] : [qsp[key]];
        if(category) {
            const categoryDictionary = {};
            category.forEach((e) => { categoryDictionary[e.value] = e });
            qsCategory.map((e) => {
                if(categoryDictionary[e]) {
                    const checkbox = categoryDictionary[e];
                    $(checkbox).prop('checked', true);
                    const event = {'target' : checkbox};
                    toggleShowAll(event, selector);
                }
            });
        }
    };
    updateCounts();
};

export const isDefaultFilterState = (selector) => {
    const filters = checkedFilters(selector);
    let defaultState = true;
    Object.keys(filters).forEach((key,index) => {
        filters[key].forEach((e) => {
            if(e.dataset.setbydefault != 'true') {
                defaultState = false;
            }
        });
    });
    return defaultState;
};

export const updateCounts = () => {
    console.log("updating counts");
    const data = checkedFiltersNames(".card-filter-menu");
    updateTheUpdatePageURL(data);
    searchAll(data).then((response) => {
        // need to clear all the values that don't have a value in response and need to update value
        $('.card-filter-menu .filter-wrapper input').each((i,input) => {

            const category = input.dataset.filtertype;
            const dataName = input.dataset.name;
            const remaining = response[category].filter((agg) => { return agg.name == dataName });
            const count = remaining && remaining.length > 0 ? remaining.map((agg) => { return agg.doc_count}) : 0;
            const label = $("label[for='"+$(input).attr('id')+"']");
            const disabled = 'disabled';

            if(count > 0 || !dataName) { //we enable the checkbox
                $(input).removeAttr(disabled);
                label.removeClass(disabled);
            } else {
                $(input).attr(disabled, disabled);
                label.addClass(disabled);
            }
        });
    }).catch((ex) => {
        console.log('exception', ex);
        throw ex;
    });
};

export class SearchFilter {

    constructor(element) {
        // two things need to happen, need to update the greyed out filters and update the count next to them
        // and then also do the search when the user hits the update button

        $(".card-filter-menu input").each((index, element) => {
            $(element).click((event) => {
                // make the ajax call for aggregations
                //toggle the buttons
                toggleShowAll(event, ".card-filter-menu");
                updateCounts();
            });
        });

        $(".card-filter-menu").each((index, element) => { updateCounts() });

    }



}
