
import $ from 'jquery';
import {slick} from 'slick-carousel';

import {searchAll, searchNews, searchCaseStudies} from './/SearchAPI';
import {resetSearchResults, setupAppendResults, loadMoreOnScroll} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/SearchUtil';
import {getCurrentQueryStringParams} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/URLUtil';
import {addHits} from './SearchResults'; 

import {MIN_SEARCH_CHARS, updateSearchQueryFromURL} from '../../../../methodstudios_menus/static/methodstudios_menus/js/SearchOverlay';

import debounce from 'throttle-debounce/debounce';

const NEWS_FEATURE_PROMOTION_SELECTOR = '.news-feature-promotion-container';

const MAX_SEARCH_PARAMS = 21;

const SEARCH_TERM_DATA_ATTRIBUTE = "searchterm"
const SEARCH_FORM_SELECTOR = "#global-search-results-form";

const getExcludeIds = () => {
    return $.map($(`${NEWS_FEATURE_PROMOTION_SELECTOR} .grid-item`), (item) => { return item.dataset.feature_id });
};

const getSearchParameters = () => {
    const searchParams = getCurrentQueryStringParams();

    if ($('body').hasClass('work-filter-page')) {

        const searchReel = $('.work-detail-filters-wrapper .work-detail-page').data('query');
        
        const featureReelRegExp = {
            sort      : '-publish_date',
            size      : /(?:s|size=)([0-9]+)(?=[^\/]*$)/,
            studios   : /(?:s|studios=)([0-9]+)(?=[^\/]*$)/,
            content   : /(?:s|content=)([0-9]+)(?=[^\/]*$)/,
            services  : /(?:s|services=)([0-9]+)(?=[^\/]*$)/,
            locations : /(?:s|locations=)([0-9]+)(?=[^\/]*$)/
        }

        searchParams.sort      = featureReelRegExp.sort;
        const setFromRegex = (searchParams, propertyName, regexpProperty) => {
            if(searchReel.match(regexpProperty) != null) {
                searchParams[propertyName] = searchReel.match(regexpProperty)[1];
            }
        };
        setFromRegex(searchParams, 'size', featureReelRegExp.size);
        setFromRegex(searchParams, 'studios', featureReelRegExp.studios);
        setFromRegex(searchParams, 'content', featureReelRegExp.content);
        setFromRegex(searchParams, 'services', featureReelRegExp.services);
        setFromRegex(searchParams, 'locations', featureReelRegExp.locations);
    }
    else {
        searchParams.sort = '-publish_date';
        searchParams.exclude = getExcludeIds();

        $(".news-search-results").each((i,e) => {
            if(e.dataset.divisionid) {
                searchParams.division_ids = e.dataset.divisionid;
            }
        });
    }

    searchParams.size = searchParams.size ? searchParams.size : MAX_SEARCH_PARAMS;
    return searchParams;
};

const moreNewsSearch = () => {
    const searchParams = getSearchParameters();
    searchParams.exclude=$('.work-detail-reference-wrapper').data('id');
    return searchParams;
};

const globalSearchParams = () => {
    const searchParams = getCurrentQueryStringParams();
    searchParams.search_term = searchParams.q;
    searchParams.size = MAX_SEARCH_PARAMS;
    return searchParams;
};

const appendNewsResults = (wrapper, response) => {
    addHits('.news-search-results .grid', response.hits);
}

const appendNewsDetailPageResults = (wrapper, response) => {

    const slickWrapper = $("#news-grid-details-page .grid-container > .grid");

    createSlick( slickWrapper );

    $.each(response.hits, function(index, el) {
        slickWrapper.slick('slickAdd', el.html);
    });
};

const appendResultsToWrapper = (wrapper, response) => {
    response.hits.forEach((hit) => {
        $(wrapper).append(hit.html);
    });
}

const appendGlobalSearchResults = (wrapper, response) => {

    const stda = $(SEARCH_FORM_SELECTOR).get(0).dataset[SEARCH_TERM_DATA_ATTRIBUTE];

    if(stda === response['search_term']) {
        appendResultsToWrapper(wrapper, response);
        $(wrapper).parents(".global-search-results-wrapper").find(".no-search-results-wrapper").each((i,e) => {
            if(response.total == 0) {
                $(e).show();
                //search term
                $(e).find(".no-results-title span").text(globalSearchParams().search_term);
                //lets show the no results;
            } else {
                //lets hide the no results
                $(e).hide();
            }
        });
    }

};

const PAGES = [
    {'selector': 'body.news-landing', 'searchFunc': searchNews, 'searchParamsFunc': getSearchParameters, 'appendResults' : appendNewsResults },
    {'selector': '#news-grid-details-page', 'searchFunc': searchNews, 'searchParamsFunc': moreNewsSearch, 'appendResults' : appendNewsDetailPageResults },
    {'selector': '#news_404', 'searchFunc': searchNews, 'searchParamsFunc': getSearchParameters, 'appendResults' : appendNewsResults },
    {'selector': '.work-detail-page', 'searchFunc': searchAll, 'searchParamsFunc': getSearchParameters, 'appendResults' : appendNewsResults },
    {'selector': '#global-search-news', 'searchFunc': searchNews, 'searchParamsFunc': moreNewsSearch, 'appendResults' : appendNewsResults }
];

const globalSearchAll = (data) => {
    const search_term = data['search_term'];
    const responsePromise = searchAll(data);

    $(SEARCH_FORM_SELECTOR).get(0).dataset[SEARCH_TERM_DATA_ATTRIBUTE] = search_term;

    responsePromise.then((response) => {
        response['search_term'] = search_term;
    });
    return responsePromise;
}

const SEARCH_PAGES = [
    {
        'selector': 'body.global-search-results .global-search-results-wrapper .search-overlay-wrapper',
        'searchFunc': globalSearchAll,
        'searchParamsFunc': globalSearchParams,
        'appendResults' : appendGlobalSearchResults
    }
]

const createSlick = ( wrapper ) => {  

    wrapper.not('.slick-initialized').slick({
        centerMode: false,
        dots: false,
        speed: 500,
        autoplay: false,
        useTransform: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        prevArrow: '<i class="ion-arrow-left-c slick-prev" data-pack="default" data-tags=""></i>',
        nextArrow: '<i class="ion-arrow-right-c slick-next" data-pack="default" data-tags=""></i>',
        accessibility: true,
        infinite: true,
        draggable: false,
    }); 

    var $carousel = $('.grid.slick-slider');
    
    $(document).on('keydown', function(e) {
        if(e.keyCode == 37) {
            $carousel.slick('slickPrev');
        }
        if(e.keyCode == 39) {
            $carousel.slick('slickNext');
        }
    });
}

const backIcon = () => {

    const hostName = document.location.hostname;
    const cameFrom = document.referrer.split('/')[2];


    $('.search-back').click(function(event){
        event.preventDefault();

        if (cameFrom == hostName ){
            window.history.go(-1);
        }
        else{
            document.location.href = "/";
        }
    });
}

const loadPageState = (pages) => {

    pages.forEach((page) => {
        $(page.selector).each((i,e) => {

            const loadMoreButtonSelector = `${page.selector} button.load-more`;
            const resultsWrapperSelector = `${page.selector} .grid-container .grid`;
            const countSelector = `${resultsWrapperSelector} .grid-item`;

            setupAppendResults(resultsWrapperSelector,
                                loadMoreButtonSelector,
                                countSelector,
                                page.searchFunc,
                                page.searchParamsFunc,
                                page.appendResults);

            resetSearchResults(resultsWrapperSelector, loadMoreButtonSelector);

            /* this loads more results when the user scrolls down. */
            loadMoreOnScroll(loadMoreButtonSelector);

        });

    });
};

const setupGlobalSearchPage = () => {
    $(".search-overlay-form form").each((i, form) => {

        const $form = $(form);
        const $input = $form.find("input");

        const resetGlobalSearch = (inputVal) => {
            $input.val(inputVal);
            updateSearchQueryFromURL(inputVal);
            $(SEARCH_PAGES[0].selector + " .grid").empty();
            $(".load-more-container").hide();
        };

        $input.on('input', debounce(350, (event) => {

            const searchinput = $(event.target);
            const searchtext = searchinput.val();
            const searchlength = searchtext.length;

            if (searchlength < MIN_SEARCH_CHARS) {
                resetGlobalSearch(searchtext);
            }
            else {
                //update the url
                $(".load-more-container").show();
                updateSearchQueryFromURL(searchtext);
                //load the page state
                loadPageState(SEARCH_PAGES);
            }
        }));
        $form.find("span").click((event) => {
            resetGlobalSearch("");
        });

        //need to check if someone got here on a link with q=
        const urlSearchParams = globalSearchParams();
        if(urlSearchParams.search_term) {
            $input.val(urlSearchParams.search_term);
            $input.trigger('input');
        }
    });
};

export class SearchGrid {
    constructor() {
        if($(SEARCH_PAGES[0].selector).length > 0) {
            setupGlobalSearchPage();
        }
        loadPageState(PAGES);
        backIcon();
    }
}
