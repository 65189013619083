// Controller for the search overlay
import $ from 'jquery';
import { SearchUtil } from './SearchUtil';

import {openMenuAddMarginsAndPadding,
        closeMenuRemoveMarginsAndPadding,
        MARGIN_RIGHT_ITEMS,
        addOrRemoveScrollingClass} from './Menu';

import {
    OPEN,
    CLOSE,
    fadeTime,
    transitionType,
    cssTransition,
    createOverlayFooter,
    getScrollbarSize,
    scrollbarExists,
    checkAndScrollIfPositionStatic,
    isStaticPosition
} from './Menu';

export const MIN_SEARCH_CHARS = 2;
const apiGlobalUrl = '/api/search/';
const searchUrl = '/search/';
const maxPredictiveResults = 5;

const SEARCH_MENU_PADDING_LEFT_ITEMS = [".search-overlay-wrapper"];

const addSearchResultsChildOffset = (addLeft) => {
    $(".search-overlay-wrapper .search-results > *").css({ paddingLeft: addLeft / 2.0 + 'px'});
};

const openSearchMenuAddMarginsAndPadding = (addLeft, addRight) => {

    openMenuAddMarginsAndPadding(
        SEARCH_MENU_PADDING_LEFT_ITEMS,
        addLeft,
        MARGIN_RIGHT_ITEMS,
        addRight
    );
    if(addLeft > 0) {
        $(".search-overlay-wrapper .search-results").css({ marginLeft: addLeft * -1 + 'px'});
        addSearchResultsChildOffset(addLeft);
    }

};

const closeSearchMenuRemoveMarginsAndPadding = () => {
    closeMenuRemoveMarginsAndPadding(SEARCH_MENU_PADDING_LEFT_ITEMS, MARGIN_RIGHT_ITEMS);
    $(".search-overlay-wrapper .search-results").css({ marginLeft: ''});
    $(".search-overlay-wrapper .search-results > *").css({ paddingLeft: ''});
};

export const updateSearchQueryFromURL = (query) => {
    const searchQuery = encodeURIComponent(query);

    if (history.replaceState && searchQuery !== 'undefined') {
        let newurl = window.location.protocol + '//' +
                     window.location.host + window.location.pathname +
                     '?q=' + searchQuery;
        window.history.replaceState({path:newurl},'',newurl);
    }
}

export class SearchOverlay {

    constructor() {
        /* only initialize if we're not on the search page */
        $("body:not(.global-search-results)").each(() => {

            this.isFetching = false;
            this.predictiveResults = false;
            this.overlayWrapper = $('.search-overlay-wrapper');
            this.form = this.overlayWrapper.find('form');
            this.controlSearch = $('.control-search');
            this.search = $('.control-search .search-toggle');
            this.overlay = $('.search-overlay');
            this.noResultsWrapper = $('.no-search-results-wrapper');
            this.contentPlaceHolder = $('.content-placeholder,.error-page');
            this.closeSearch = $('.search-overlay-wrapper .search-close .closesearch');
            this.resetSearch = this.overlayWrapper.find('.search-overlay span');
            this.searchResults = this.overlayWrapper.find('.search-results');
            this.searchInput = this.overlay.find('input');
            this.lens = this.overlay.find('.search');
            this.selectedItem = false;
            this.mainMenu = $('.main-menu');
            this.searchText = '';
            this.isMobilePage = this.checkMobile();
            this.isSearchOpen = false;
            this.scrollSize = getScrollbarSize();
            this.seeAllResults = $('.search-overlay-wrapper .see-all-results-wrapper');

            this.initSearchOverlay();
            this.onResize();
            createOverlayFooter(this.overlayWrapper);
        });
    }

    /**
     * Adds a listener for screen resizing or rotating to change menu behaviour and layout on resize
     */
    onResize() {
        $(window)
            .off('resize.search.window')
            .on('resize.search.window', () => {
                if (!this.isSearchOpen) {
                    return;
                }

                const thisWasMobile = this.isMobilePage;
                this.isMobilePage = this.checkMobile();

                // Check if screen changed mobile <--> desktop
                if (thisWasMobile !== this.isMobilePage) {
                    if (this.isSearchOpen) {
                        this.resetOverlayWrapper();
                    }
                }
            });
    }

    /**
     * Initialize listeners for search overlay buttons
     */
    initSearchOverlay() {
        // Set submit action to the search page if we're not in it!
        $('.search-overlay form').attr('action', searchUrl);

        this.overlayWrapper.css({
            transition: `background ${cssTransition}s ${transitionType},
                opacity ${cssTransition}s ${transitionType}`
        });

        // Search main menu button
        this.search
            .off('click.search.open')
            .on('click.search.open', () => {
                this.setOverlayWrapper(OPEN);
                this.isSearchOpen = true;
            });

        // Close main menu button
        this.closeSearch
            .off('click.search.close')
            .on('click.search.close', () => {
                setTimeout(() => {
                    this.isSearchOpen = false;
                    this.closeSearchOverlay();
                }, fadeTime / 2.0 + 1);
            });

        // Search bar cancel button
        this.resetSearch
            .off('click.search.reset')
            .on('click.search.reset', () => this.resetAll());

        // Pressed less button
        this.lens
            .off('click.lens.button')
            .on('click.lens.button', () => this.getSearchResults());

        this.seeAllResults
            .off('click.see.all')
            .on('click.see.all', (event) => {
                event.preventDefault();
                this.getSearchResults();
            });

        // Search input field key press
        this.searchInput
            .off('input.search.input')
            .on('input.search.input', (event) => {

                if (event.keyCode === 27) {
                    this.closeSearchOverlay();
                    return;
                }

                if (event.keyCode === 13) {
                    this.getSearchResults();
                    return;
                }

                const searchinput = $(event.target);
                const searchtext = searchinput.val();
                const searchlength = searchtext.length;
                
                updateSearchQueryFromURL( searchtext );

                if (searchlength >= MIN_SEARCH_CHARS) {

                    this.searchResults.find('h4').slideDown();
                    this.searchText = searchtext;
                    this.searchPredictive(searchtext)
                        .then(() => {
                            // Avoid collision of search promises
                            //this.searchText != searchtext if we have two requsts in a row
                            //and the first request takes longer then a follow up request
                            if (!this.isFetching && this.searchText == searchtext) {
                                this.searchResults.find('.card, .grid-item').remove();
                                const results = this.predictiveResults;
                               
                                if (results.total > 0) {
                                    const cards = this.buildPredictiveResults(results, 'card');
                                    $(cards).insertBefore(this.searchResults.find('.see-all-results-wrapper'));
                                    addSearchResultsChildOffset(this.calculateAddLeft());
                                    this.noResultsWrapper.hide();
                                    this.setResultsHoverClick();
                                    if (results.total > 5) {
                                        this.seeAllResults.show();
                                    } else {
                                        this.seeAllResults.hide();
                                    }

                                } else {
                                    this.noResults(searchtext);
                                    this.noResultsWrapper.show();
                                    this.seeAllResults.hide();
                                    this.resetPredictiveResults();
                                }
                            }
                        });
                } else {
                    this.searchText = searchtext;
                    this.noResultsWrapper.hide();
                    this.resetPredictiveResults();

                    this.searchResults.find('h4').slideUp();
                    this.seeAllResults.slideUp();
                }
            });

    }

    calculateAddLeft() {
        return scrollbarExists(this.overlayWrapper) ? this.scrollSize : 0;
    }

    /**
     * @param action
     * Set up search overlay layout according to current device / screen size
     */
    setOverlayWrapper(action) {
        this.isMobilePage = this.checkMobile();

        if (action === OPEN) {
            this.overlayWrapper.addClass('opening');
            $('body').addClass('on-overlay');

            const mainMenuNotClosedBySearch = $('.main-menu-wrapper').data('closedbysearch') !== true;

            if (!this.isMobilePage) {
                let addRight = scrollbarExists() ? this.scrollSize + 'px' : 0;
                let addLeft = this.calculateAddLeft();

                if (mainMenuNotClosedBySearch) {
                    openSearchMenuAddMarginsAndPadding(addLeft, addRight);
                } else {
                    openSearchMenuAddMarginsAndPadding(addLeft, null);
                }

            }
            this.fadeInSearch();
            this.searchInput.focus();
        } else {
            this.fadeOutSearch();
        }
    }

    fadeInSearch() {
        this.isMobilePage = this.checkMobile();

        this.searchInput.css('opacity', 0);

        const closeMainMenuIfClosedBySearch = () => {
            if ($('.main-menu-wrapper').data('closedbysearch') === true) {
                    $('.main-menu-wrapper, .main-menu-wrapper .control-search')
                        .removeClass('opened closing')
                        .addClass('closed')
                        .data('closedbysearch', false);
            }
        };

        if(isStaticPosition(this.overlayWrapper)) {
            closeMainMenuIfClosedBySearch();
        }

        setTimeout(() => {
            this.overlayWrapper
                .removeClass('opening')
                .addClass('opened');

            setTimeout(() => {
                this.controlSearch
                    .removeClass('opened closed search')
                    .addClass('search');

                this.searchInput.fadeTo(fadeTime / 2, 1);

                closeMainMenuIfClosedBySearch();
            }, fadeTime);
        }, 20);

    }

    fadeOutSearch() {
        this.controlSearch
            .removeClass('opened closed search')
            .addClass('closed');
        this.searchInput.fadeTo(fadeTime / 2, 0);
        this.overlayWrapper.removeClass('opened').addClass('opening');

        //need to call Menu
        addOrRemoveScrollingClass();

        setTimeout(() => {
            this.overlayWrapper.removeClass('opening');
            $('body').removeClass('on-overlay');
            closeSearchMenuRemoveMarginsAndPadding();
            if (!this.isMobilePage) {
                this.overlayWrapper.css({ paddingLeft: '' });
            }

        }, fadeTime);
    }

    /**
     * Resets search overlay wrapper in case of resize / rotate device
     */
    resetOverlayWrapper() {
        if (this.isMobilePage) {
            $('body')
                .removeClass('on-overlay')
                .css({ marginRight: 0 });
        } else {
            let addRight = scrollbarExists() ? this.scrollSize + 'px' : '';
            $('body')
                .addClass('on-overlay')
                .css({ marginRight: addRight });
            this.overlayWrapper.css({ paddingRight: addRight });
        }
    }

    noResults(searchText) {
        this.noResultsWrapper.find('.no-results-title span').text(searchText);
    }

    setResultsHoverClick() {
        const that = this;

        this.searchResults
            .find('.card')
            .off('mouseover.predictive')
            .on('mouseover.predictive', function() {
                that.selectedItem = $(this).data('hitindex');
            })
            .off('click.predictive')
            .on('click.predictive', function() {
                that.selectedItem = $(this).data('hitindex');
                that.getResultDetails();
            });
    }

    /**
     * Clear predictive results leaving results area open
     */
    resetPredictiveResults() {
        this.predictiveResults = false;
        this.searchResults.find('.card').remove();
        this.seeAllResults.hide();
    }

    /**
     * Clear predictive results as well as input field and hiding results area
     */
    resetAll() {

        this.searchResults.find('h4').hide();
        this.searchInput.val('');
        this.noResultsWrapper.hide();
        this.resetPredictiveResults();

        let cleanURL = window.location.href.split("?q=")[0];

        window.history.replaceState(null, null, cleanURL);
    }

    /**
     * Hides search overlay including bar and shows main menu buttons and content
     */
    closeSearchOverlay() {
        this.resetAll();
        this.setOverlayWrapper(CLOSE);
    }

    /**
     * @param {Object} results
     * Build HTML for the predictive results
     */
    buildPredictiveResults(results, resultType) {

        const hits = results.hits.slice(0, maxPredictiveResults);

        let cards = '';
        let hitIndex = 0;


        if ( resultType == 'card' ) {
            Object.keys(hits).forEach((hitindex) => {

                const hit = hits[hitindex];
                const title = hit.title;
                const content = hit.content || false;
                const services = hit.services || false;

                cards += `<div class="card" data-hitindex="${hitIndex}">
                    <h5>${title}</h5>
                </div>`;

                hitIndex++;
            });
        }
        if ( resultType == 'grid' ) {
            Object.keys(hits).forEach((hitIndex) => {
                let pills = '';
                const hit = hits[hitIndex];
                const title = hit.title;
                const image = hit.image;
                const link = hit.linkUrl;

                const parsed = $.parseHTML(hit.html.trim());
                parsed[0].dataset['hitindex'] = hitIndex;
                cards += parsed[0].outerHTML;

                hitIndex++;
            });
        }

        return cards;
    }

    /**
     * @param searchText
     * @returns {Promise<Response>}
     */
    searchPredictive(searchText) {
        const params = {search_term: searchText, size: maxPredictiveResults + 1};

        this.isFetching = true;
        this.searchResults.css('opacity', '.7');
        return SearchUtil.fetchJSON(apiGlobalUrl, params)
            .then((data) => {
                this.predictiveResults = data;
                this.isFetching = false;
                this.selectedItem = 0;
                this.searchResults.css('opacity', '1');
            });
    }

    /**
     * Goes to the search page with the selected predictive result or an empty query
     */
    getSearchResults() {
        let paramsobj;
        let searchQuery;
        searchQuery = this.searchText;
        paramsobj = { q: searchQuery };

        SearchUtil.setUrl(searchUrl, paramsobj);

    }

    /**
     * Goes to the details page of the clicked predictive result
     */
    getResultDetails() {
        let detailsUrl = '';
        if (this.predictiveResults) {
            detailsUrl = this.predictiveResults.hits[this.selectedItem].linkUrl;
            SearchUtil.setUrl(detailsUrl);
        }
    }

    checkMobile() {
        const mediaProperty = window.getComputedStyle(document.body, ':after');
        const screen = mediaProperty.content;
        return screen !== '"desktop"';
    }
}
