import $ from 'jquery';
global.$ = $;
require('easy-autocomplete');

import base64url from "base64url";


import {getCareers} from './CareersSearchAPI';

import {getCurrentQueryStringParams, generateUrl} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/URLUtil';
import {addHoverClasses, closeAllSubMenus} from '../../../../../deluxe_menus/deluxe_menus/static/deluxe_menus/js/MenuUtil';
import {addInputClear} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/InputUtil';

import {isPhoneWidth} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/WindowUtil';

const LOAD_MORE_BUTTON_SELECTOR = '.career-search-container .load-more';
//const career_search_settings = {'selector': 'body.careers-search-page', 'loadMoreButtonSelector': '.career-search .load-more', 'resultsWrapperSelector': '.career-search-results', 'countSelector': '.career-search-results .search-result', 'searchFunc': getCareers, 'searchParamsFunc': getSearchParameters, 'appendResults' : appendCareersResults };

const formatDate = (dateTimeString) => {

    const zeroPadded = (value) => {
        const stringValue = String(value);
        return stringValue.length < 2 ? '0' + stringValue : stringValue;
    };

    const dateArray = dateTimeString.split('T')[0].split('-');
    const year = dateArray[0];
    const month = zeroPadded(dateArray[1]);
    const day = zeroPadded(dateArray[2]);
    return `${month}/${day}/${year}`;
};

const generateSearchItem = (searchResponseItem) => {
    let locations = '';
    if(!!searchResponseItem.locations && searchResponseItem.locations.length > 0) {
        locations = searchResponseItem.locations.map(location => {
            const city = !!location.city ? location.city + ", " : "";
            const state = !!location.state ? location.state + ", " : "";
            return `${city}${state}${location.country_code}`
        }).join(' | ') + ' | ';
    }
    if(!searchResponseItem.family) {
        searchResponseItem.family = {"family_function": ""};
    }

    const formattedPublishDate = 'Posted ' + formatDate(searchResponseItem.publish_date);

    return `<a href="../detail/${searchResponseItem.workday_id}" class="search-result">
                <h2>${searchResponseItem.title}</h2>
                <div class="details">${locations}${searchResponseItem.family.family_name} | ${searchResponseItem.employment_type}</div>
                <div class="meta-data">${formattedPublishDate} | Job ID ${searchResponseItem.workday_id}</div>
            </a>`;
};

const getSearchParameters = (formElement) => {
    const $formElement = $(formElement);
    let data = {};
    $formElement.find("input").each((i,e) => {
        const $e = $(e);
        if($e.attr('name') == 'location') {
            const {country_code, state, city} = extractCountryCodeCityFromLocation($e);
            data['country_code'] = country_code;
            data['state'] = state;
            data['city'] = city;

        }
        else if($e.attr('name') == 'search_term') {
            data[$e.attr("name")] = $e.val().length > 1 ? $e.val() : '';
        }
        else {
            switch(e.type) {
                case "checkbox":
                    if($e.is(":checked")) {
                        if(!!data[$e.attr('name')]) {
                            data[$e.attr('name')].push($e.val());
                        } else {
                            data[$e.attr('name')] = [$e.val()];
                        }
                    }
                    break;
                case "radio":
                    if($e.is(":checked")) {
                        data[$e.attr('name')] = $e.val();
                    }
                    break;
                default:
                    data[$e.attr("name")] = $e.val();
            }
        }

    });

    data.size = 5;
//    console.log("getSearchParameters:");
//    console.log(data);
    return data;
};

const appendSearchResults = (formElement, encodedHash, response) => {
    const $formElement = $(formElement);
    const encodedSearchHash = $formElement.data('encodedsearchhash');
    if(encodedHash === encodedSearchHash) {
        const resultsHtml = response.hits.map((e) => generateSearchItem(e)).join("");
        $formElement.siblings('.career-search-results').append(resultsHtml);

        if(response.total > $formElement.siblings('.career-search-results').find('.search-result').length) {
            const $loadMoreButton = $formElement.siblings(".load-more");
            $loadMoreButton.removeAttr("disabled");
            $loadMoreButton.show();
        }
        $(".career-search-results-total span").text(`${response.total}`);
    }

};

const executeSearch = (formElement) => {
    const $formElement = $(formElement);
    const $loadMoreButton = $formElement.siblings(".load-more");
    $loadMoreButton.attr("disabled","disabled");
    $loadMoreButton.hide();
    const data = getSearchParameters($formElement);
    //here we want to create a bse64 encoded hash of the parameters
    const encodedData = base64url(JSON.stringify(data));
    const encodedSearchHash = $formElement.data('encodedsearchhash');
//    console.log(`encodedData: ${encodedData}, encodedSearchHash: ${encodedSearchHash}`);
    let page = 0;

    if(encodedSearchHash && encodedData === encodedSearchHash) {
        page = parseInt($(LOAD_MORE_BUTTON_SELECTOR).data('page')) + 1;
    } else {
        $formElement.data('encodedsearchhash', encodedData);
        //reset the results as we're changing the hash.
        $formElement.siblings('.career-search-results').html('');
    }
    $(LOAD_MORE_BUTTON_SELECTOR).data('page', page);
    data.page = page;

    const ajaxPromise = getCareers(data);
    ajaxPromise.then((response) => {
        appendSearchResults($formElement, encodedData, response);
    });
};

const initializeSearchFromQueryString = ($formElement) => {
    const qsp = getCurrentQueryStringParams();
    $formElement.find("input").each((i,e) => {
        const $e = $(e);
        const name = $(e).attr('name');
        if(name == 'location') {
            let location = '';
            location = !!qsp['country_code'] ? qsp['country_code'] : '';
            if(!!location) {
                location = (!!qsp['state'] ? qsp['state'] + ', ' : '') + location;
                location = (!!qsp['city'] ? qsp['city'] + ', ': '') + location;
                $e.val(location);
            }
        } else if(qsp && !!qsp[name] && name != 'site_id') {
            switch(e.type) {
                case "checkbox":
                    let x = qsp[name];
                    x = ( typeof x != 'undefined' ) ? x : [];
                    x = ( x instanceof Array ) ? x : [x];
                    if(x.indexOf($e.val()) > -1) {
                        $e.prop('checked', true);
                    }
                    updateFilterHeader(e, x);
                    break;

                case "radio":
                    if($e.val() == qsp[name]) {
                        $e.prop('checked', true);
                    }
                    updateSortHeader($e);
                    break;

                default:
                    $e.val(qsp[name]);
            }
        }
    });
};

const updateFilterHeader = (input, inputValues) => {

    $(input).parents('.search-filter').find('.filter-header').each((i,e) => {
        const $filterHeader = $(e);
        let countHtml = '';
        let pluralHtml = '';
        const selections = "selections";
        if(!!inputValues && inputValues.length > 0) {
            countHtml = `<i class="ion-close"></i> ${inputValues.length} `;
            if (!!inputValues && inputValues.length > 1) {
                pluralHtml = 's';
            }
            $filterHeader.addClass(selections);
        } else {
            $filterHeader.removeClass(selections);
        }
        $filterHeader.find('.count').html(countHtml).find("i").on('click', (event) => {
            //this will reset the header
            $filterHeader.parents('.search-filter').find('ul input').prop('checked', false);
            updateFilterHeader(input, []);
            pushState((data) => {data[$(input).attr('name')] = [];});
            executeSearch($filterHeader.parents('#career-search-form'));
        });
        $filterHeader.find('.plural').html(pluralHtml);
    });
};

const updateSortHeader = (input) => {
    const $input = $(input);
    $input.parents('.search-sort').find('.sort-header').each((i,e) => {
        const $sortHeader = $(e);
        const oldSortString = $(e).text().trim().split(" ");
        oldSortString.pop();
        const newSortString = oldSortString.join(' ') + ` ${$input.siblings('span').text()}`;
        $sortHeader.text(newSortString);
    });

};

const extractCountryCodeCityFromLocation = (locationInput) => {
    const locationid = $(locationInput).val().split(',').map((i) => i.trim()).join("");
    let result = {};
    if(locationid) {
        const $location = $(`#location-list [data-locationid="${locationid}"]`).first();
        const country = $location.find(".country_code").text();
        const state = $location.find(".state").text();
        const city = $location.find(".city").text();
        result = {"country_code": country, "state": state.substr(0, state.length - 2),"city": city.substr(0, city.length - 2)};
    }

    return result;
};

const pushState = (updateFunc) => {
    const data = getCurrentQueryStringParams();
    updateFunc(data);
    const url = generateUrl(window.location.pathname, data);
    window.history.pushState(data, null, url);
};

const addEventListeners = ($formElement) => {

    const $searchInput = $formElement.find('input[name=search_term]');
    $searchInput.on('input', (event) => {
        const $element = $(event.currentTarget);
        pushState((data) => {data[$element.attr('name')] = $element.val();});
        executeSearch($formElement);
    });
    addInputClear(
        $searchInput,
        () => { $searchInput.trigger('input'); }
    );
    //DXCAREERS-157 - hiting enter on android keyboard doesn't remove keyboard
    $formElement.find("input").keypress((event) => {
        if (event.which == 13) {
            $(event.currentTarget).blur();
        }
    });

    $formElement.find(".career-location input").each((i,e) => {
        const $e = $(e);
        const locationList = $e.siblings('#location-list').find('li').map((i,e) => e.textContent.trim()).toArray();

        const onChooseEvent = () => {
            const {country_code, state, city} = extractCountryCodeCityFromLocation($e);
            pushState((data) => {
                data['country_code'] = country_code;
                data['state'] = state;
                data['city'] = city;

            });
            executeSearch($formElement);
        };

        $e.easyAutocomplete({
            data: locationList,
            list: {
                match: {
                    enabled: true
                },
                onHideListEvent: () => {
                    if(locationList.indexOf($e.val()) < 0) {
                        $e.val('');
                        onChooseEvent();
                    } else {
                        onChooseEvent();
                    }
                }
            },
            noResults: 'No Locations',
        });

        addInputClear(
            $e,
            () => { /* onHideList is called anyways. onChooseEvent(); */ }
        );

    });
    $formElement.find(".search-filter label input").on('click', (event) => {

        const $input = $(event.currentTarget);
        if($input.is(":checked")) {
            $input.prop('checked', true);
        } else {
            $input.prop('checked', false);
        }

        //need to get all the values for this checkbox.
        const inputName = $input.attr('name');
        const values = $formElement.find(`input[name="${inputName}"]`)
        .filter((i,e) => $(e).is(":checked")).map((i,e) => $(e).val()).toArray();

        //need to update the count and show the 'x'
        updateFilterHeader($input, values);

        pushState((data) => {data[inputName] = values;});
        executeSearch($formElement);

    });

    $formElement.find(".search-sort label input").on('click', (event) => {

        const $input = $(event.currentTarget);
        const inputName = $input.attr('name');
        const value = $input.val();
        //need to update the count and show the 'x'
        updateSortHeader($input, value);

        pushState((data) => {data[inputName] = value;});
        executeSearch($formElement);

    });

    $formElement.siblings(".load-more").on('click', (event) => {
        executeSearch($formElement);
    });

    $formElement.on('submit', (event) => {
        event.preventDefault();
    });
};

const setupMobileMenu = (formElement) => {
    const $formElement = formElement;

    $formElement.find('.filter-icon').on('click', (event) => {
        if($formElement.hasClass('mobile-version')) {
            $('body').addClass('career-search-mobile-filter');
        }
    });

    const toggleMobileVersionClass = () => {
        if(isPhoneWidth()) {
            $formElement.addClass('mobile-version');
        } else {
            $formElement.removeClass('mobile-version');
            $('body').removeClass('career-search-mobile-filter');
        }
    };

    $formElement.find('.menu-header a').on('click', (event) => {
        event.preventDefault();
        //close all sub menus here
        closeAllSubMenus($(event.currentTarget).parents('#career-search-form').find(".search-filter, .search-sort"));
        $('body').removeClass('career-search-mobile-filter');
    });

    $(window).resize(toggleMobileVersionClass);
    toggleMobileVersionClass();
};

export class CareersSearch {
    constructor() {
        $('body.careers-search-page').each((i,e) => {
            const $formElement = $('#career-search-form');
            initializeSearchFromQueryString($formElement);
            executeSearch($formElement);
            addEventListeners($formElement);

            addHoverClasses($formElement.find(".search-filter, .search-sort"));
            setupMobileMenu($formElement);


            window.addEventListener('popstate', function(e) {
                window.location.reload();
            });

            //$('.filter-icon').trigger('click');
        });
    }
}


