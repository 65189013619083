import qs from 'qs';
import $ from 'jquery';
import debounce from 'throttle-debounce/debounce';

import {getCurrentQueryStringParams, parseUrlForQueryStringParams, generateUrl} from './URLUtil';

export const NO_SEARCH_RESULTS_WRAPPER_SELECTOR = ".no-search-results-wrapper";

export const resetSearchResults = (resultsWrapperSelector, loadMoreSelector) => {
    $(resultsWrapperSelector).html('');
    $(loadMoreSelector).each((i,e) => {
        const $e = $(e);
        $e.data('page', 0); //reset page number
        $e.trigger('click');
    });
};

const defaultAppendResultsFunc = (wrapper, response) => {
    response.hits.forEach((hit) => {
        $(wrapper).append(hit.html);
    });
}

export const setupAppendResults = (resultsWrapperSelector,
                                    loadMoreSelector,
                                    countSelector,
                                    searchAPIFunc,
                                    searchParamsFunc = getCurrentQueryStringParams,
                                    appendResultsFunc = defaultAppendResultsFunc) => {
    $(loadMoreSelector).off('click').on('click', (event) => {
        const $button = $(event.target);
        if(!$button.prop('disabled')) {
            //grab the query string searchParams from the link
            const searchParams = searchParamsFunc();
            searchParams['page'] = parseInt($button.data('page'));
            searchAPIFunc(searchParams).then((response) => {
                //append the cards if the page number is correct
                if(searchParams['page'] == $button.data('page')) {
                    //we only add the data if it's time to add them.
                    $button.data('page', searchParams['page'] + 1);

                    $(resultsWrapperSelector).each((i,r) => {
                        appendResultsFunc(r, response);
                        //count the total number of cards and see if it's less the total then we still need to keep adding.
                        //since packery doesn't immediately add the items we have to run this in a loop, just
                        //to make sure that the result is correct.
                        [100,300,500,1000].forEach((interval) => {
                            setTimeout(() => {
                                if($(countSelector).length < response.total) { //update the anchor tag
                                    $button.show();
                                } else {
                                    $button.hide();
                                }
                            }, interval);
                        });
                    });
                }
            });
        }
    });
};

export const loadMoreOnScroll = (loadMoreButtonSelector) => {
    /* this loads more results when the user scrolls down. */
    $(document).ready(function() {
        const win = $(window);
        //need to debounce this so there is enough time to mark load more button as not visible.
        win.unbind("scroll").scroll(debounce(200, (event) => {
            const $loadMoreButton = $(loadMoreButtonSelector);
            const offset = $loadMoreButton.offset();
            if (!!offset && win.scrollTop() + win.height() >= offset.top && $loadMoreButton.is(":visible")) {
                $loadMoreButton.trigger('click');
            }
        }));
    });
};

//should be used like
//{'selector': '...', 'loadMoreButtonSelector': '...', 'resultsWrapperSelector': '...', 'countSelector': '...', 'searchFunc': searchNews, 'searchParamsFunc': getSearchParameters, 'appendResults' : appendNewsResults }
const infiniteScrollSearch = (page) => {
    $(page.selector).each((i,e) => {

        setupAppendResults(page.resultsWrapperSelector,
                            page.loadMoreButtonSelector,
                            page.countSelector,
                            page.searchFunc,
                            page.searchParamsFunc,
                            page.appendResults);

        resetSearchResults(page.resultsWrapperSelector, page.loadMoreButtonSelector);

        /* this loads more results when the user scrolls down. */
        loadMoreOnScroll(page.loadMoreButtonSelector);
    });


};

