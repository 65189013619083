import $ from 'jquery';
import debounce from 'throttle-debounce/debounce';
import {isCarouselHeader} from '../../../../bydeluxe/deluxe_menus/deluxe_menus/static/deluxe_menus/js/MenuUtil.js';


export class HomepageFullscreenCarousel {
    constructor() {
        $("body.homepage").each((i, e) => {
            const $footer = $('footer').last();
            const $carousel = $('.carousel.large').first();
            const isHeader = isCarouselHeader($carousel);
            $(document).ready(function() {
                if(isHeader) {
                    const $win = $(window);
                    //need to debounce this so there is enough time to mark load more button as not visible.
                    $win.scroll(debounce(150, (event) => {
                        const lowestScrollPosition = $win.scrollTop() + $win.height();
                        const footerOffset = $footer.offset();
                        if(lowestScrollPosition < footerOffset.top) {
                            $carousel.css('visibility', 'visible');
                        } else {
                            $carousel.css('visibility', 'hidden');
                        }
                    }));
                }
            });

            $(window).on('mainmenu:close', (event) => {
                $('.carousel.large').first().css('visibility', 'visible');
            });

        });
    }
}
