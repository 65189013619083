// Js controller class for landing production grid

import $ from 'jquery';
import Packery from 'packery';
const productionGridColumns = 4;

export class ProductionGrid {
    constructor() {
        this.productionGrid = $('.production-grid-content');
        if (!this.productionGrid.length) {
            return;
        }
        this.gridItems = this.productionGrid.find('.production-grid-item');
        this.prodPackery = false;
        this.containerMaxHeight = false;
        this.initProduction();
    }

    /**
     * Work grid carousel initializer
     */
    initProduction() {
        this.initResetImages();
        let resizeTimer;

        $(window).resize(() => {
            // Debounce resizing
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.productionGrid.removeAttr('style');
                this.initResetImages();
            }, 250);
        });
    }

    initResetImages() {
        const mediaProperty = window.getComputedStyle(this.productionGrid[0], ':after');
        const screen = mediaProperty.content;
        if (screen === '"desktop"') {
            this.desktopProductionImages();
        } else {
            this.mobileProductionImages();
        }
    }

    /**
     * Preloads images before applying packery
     */
    desktopProductionImages() {
        const imgNumber = this.gridItems.length;
        const that = this;
        const containerWidth = this.productionGrid.width();
        this.containerMaxHeight = containerWidth / productionGridColumns * 2;
        let containerMaxHeight = this.containerMaxHeight;
        let imagesLoaded = 0;

        this.productionGrid.height(this.containerMaxHeight);

        this.gridItems.each(function(index) {
            const thisItem = $(this);
            const thisImg = thisItem.find('img');
            const thisSrc = thisImg.attr('src');
            const newImg = new Image();
            let imageHeight = '';

            // Forces the first image to be the 4x one. TODO: Remove when data-size is ready
            if (index % 3 === 0) {
                thisItem.data('size', '4');
                imageHeight = containerMaxHeight;
            } else {
                thisItem.data('size', '1');
                imageHeight = Math.floor(containerMaxHeight / 2);
            }

            thisItem.removeAttr('style');
            thisItem.addClass(`grid-${thisItem.data('size')}x`);
            thisItem.css({ height: imageHeight });

            newImg.onload = function() {
                imagesLoaded++;

                if (imagesLoaded === imgNumber) {
                    // Images loaded
                    that.initProductionGrid();
                }
            };

            newImg.src = thisSrc;
        });
    }

    /**
     * Preloads images before applying carousel
     */
    mobileProductionImages() {
        const imgNumber = this.gridItems.length;
        const that = this;
        let imagesLoaded = 0;

        this.gridItems.each(function() {
            const newImg = new Image();
            const thisItem = $(this);
            const thisImg = thisItem.find('img');
            const thisSrc = thisImg.attr('src');

            thisItem.removeAttr('style');

            newImg.onload = function() {
                imagesLoaded++;
                if (imagesLoaded === imgNumber) {
                    // Images loaded
                    that.initProductionCarousel();
                }
            };

            newImg.src = thisSrc;
        });
    }

    initProductionGrid() {
        this.gridItems.find('img').animate({ opacity: 1}, 300);

        this.prodPackery = new Packery('.production-grid-content', {
            percentPosition: true,
            resize: false
        });

        this.prodPackery.on( 'layoutComplete', () => {
            const bigPic = this.productionGrid.find('.grid-4x');
            this.prodPackery.fit(bigPic, 0);
        });

        this.prodPackery.layout();
    }

    initProductionCarousel() {
        if (!this.productionGrid.hasClass('slick-initialized')) {
            this.setProductionSlick();
        }
    }

    setProductionSlick() {
        const that = this;

        this.productionGrid.on('init', function() {
            that.gridItems.find('img').animate({ opacity: 1}, 300);
            $(this).data('swiped', false);
            $(this).find('.slick-track').animate({ marginLeft: '-18%' }, 300);
        });

        this.productionGrid.slick({
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: 'unslick'
                },
                {
                    breakpoint: 320,
                }
            ],
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay: false,
            useTransform: false,
            variableWidth: false,
            arrows: false,
        });

        this.productionGrid.on('afterChange', function() {
            if (!$(this).data('swiped')) {
                $(this).find('.slick-track').animate({ marginLeft: 0 }, 300);
                $(this).data('swiped', true);
            }
        });
    }
}
