// Js controller class for landing production grid

import $ from 'jquery';

export class WorkGrid {
    constructor() {
        this.workGrid = $('.careers-landing .grid-container .grid');
        if (!this.workGrid.length) {
            return;
        }
        this.initWorkGrid();
    }

    /**
     * Work grid carousel initializer
     */
    initWorkGrid() {
        this.setWorkSlick();

        $(window).on('resize.work', () => {
            if (!this.workGrid.hasClass('slick-initialized')) {
                this.setWorkSlick();
            }
        });
    }

    setWorkSlick() {
        this.workGrid.on('init', function() {
            //here we going to center the content so that it doesn't look pushed
            //off to one side.
            const width = $(global.document).width();
            const firstItemWidth = $(this).find(".grid-item").first().width()
            const difference = width - firstItemWidth;
            if(difference < 0) {
                $(this).find('.slick-track').css({ marginLeft: `${difference/2}px` });
                $(this).find('.slick-track .content p').css({ marginLeft: `${difference/-2}px` });
            }

        });

        this.workGrid.slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay: false,
            useTransform: false,
            variableWidth: true,
            arrows: true,
            draggable: false,
            prevArrow: '<i class="ion-arrow-left-c slick-prev" data-pack="default" data-tags=""></i>',
            nextArrow: '<i class="ion-arrow-right-c slick-next" data-pack="default" data-tags=""></i>',
            accessibility: true,
            responsive: [
            {
                breakpoint: 991,
                settings: 'unslick'
            }]
        });

        this.workGrid.on('destroy', function() {
            $(this).find('.grid-item').css({ left: 'inherit' });
        });
    }
}
