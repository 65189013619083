// Utilities class for searching functionalities

import qs from 'qs';

export class SearchUtil {
    static checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

    static parseJSON(response) {
        return response.json();
    }

    static fetchJSON(url, paramsobj) {
        const params = qs.stringify(paramsobj);
        return fetch(`${url}?${params}`, { credentials: 'same-origin' })
            .then(this.checkStatus)
            .then(this.parseJSON);
    }

    static setUrl(url, paramsobj = false) {
        if (paramsobj) {
            const params = qs.stringify(paramsobj);
            location.href = `${url}?${params}`;
        } else {
            location.href = url;
        }
    }
}
