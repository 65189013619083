import $ from 'jquery';
global.jQuery = $;

import {videoInit} from '../../../../bydeluxe/deluxe_features/features/static/features/js/Video';

export class News {
	constructor() {
		$('.video-with-text-wrapper').each((i,item) => {
		    videoInit($(item).find('.video-js'));
		});
	}
}
