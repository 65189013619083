import $ from 'jquery';
import {slick} from 'slick-carousel';

export class AboutCarousel {
    constructor() {
        this.stripe = $('.leadership-grid-content');
        this.initCarousel();
    }

    initCarousel() {
        this.setSlick();

        $(window).resize(() => {
            if (!this.stripe.hasClass('slick-initialized')) {
                this.setSlick();
            }
        });
    }

    setSlick() {
        this.stripe.on('init', function() {
            $(this).data('swiped', false);
            $(this).find('.slick-track').animate({ marginLeft: '-18%' }, 300);
            $(this).find('.slick-track .slick-slide .leadership-grid-item a > div').animate({ marginLeft: '18%' }, 300);
        });

        this.stripe.slick({
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: 'unslick'
                },
                {
                    breakpoint: 320,
                }
            ],
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay: false,
            useTransform: false,
            variableWidth: false,
            arrows: false,
        });

        this.stripe.on('afterChange', function() {
            if (!$(this).data('swiped')) {
                $(this).find('.slick-track').animate({ marginLeft: 0 }, 300);
                $(this).find('.slick-track .slick-slide .leadership-grid-item a > div').animate({ marginLeft: 0 }, 300);
                $(this).data('swiped', true);
            }
        });
    }
}
