import $ from 'jquery';
import {slick} from 'slick-carousel';

export class TwoTextColumn {
    constructor() {
        const CLOSED_CLASS = "closed";
        $('.two-column-wrapper .accordion-expand-contract')
          .click((event) => {
            event.preventDefault();
            const $button = $(event.currentTarget);
            if($button.hasClass(CLOSED_CLASS)) {
              $('.two-column-wrapper .accordion-content, .two-column-wrapper .less').show();
              $('.two-column-wrapper .more').hide();
              $button.removeClass(CLOSED_CLASS);
            } else {
              $('.two-column-wrapper .accordion-content, .two-column-wrapper .less').hide();
              $('.two-column-wrapper .more').show();
              $button.addClass(CLOSED_CLASS);
            }
          });
    }
}
