import $ from 'jquery';
import TinyDatePicker from 'tiny-date-picker';

import { isValidForm } from './Validation';

export class Careers {
	initCareers() {
		this.careerTitle.click(function(event) {
			$(this)
			  .toggleClass('active')
			  .next().find('.career-jobs')
			  .slideToggle(300);
		});
		
		this.careerItemTitle.click(function(event) {

			let careerJobsParent = $(this).parents().eq(3);
			let careerDetailsHolder = careerJobsParent.find('.career-details-holder > div');

			careerDetailsHolder.html($(this).next().html());

			careerJobsParent.find('.career-jobs-holder').fadeOut(300, function() {
				careerJobsParent.find('.career-details-holder').fadeIn(300, function(){
					$('html, body').animate({
						scrollTop: careerDetailsHolder.offset().top - 150
					}, 2000);
				});
			});
		});
		
		const careerGoBack = $('.career-jobs .back');


		careerGoBack.click(function(event) {
			const careerBackBtnParent = $(this).parents().eq(1);

			careerBackBtnParent.find('.career-details-holder').fadeOut(300, function() {
				careerBackBtnParent.find('.career-jobs-holder').fadeIn(300);
			});
		});
    }

	constructor() {
		this.careerTitle = $('.careers-wrapper .career-title');
		this.careerItemTitle = $('.careers-wrapper .career-item h4.title');
		
		const formContainer = $('#application-form');
		const validateButton = formContainer.find('button.submit');
		if($(".datepicker").length) {
            TinyDatePicker('.datepicker', {
              mode: 'dp-below',
              min: Date()
            });
        }

		isValidForm(formContainer, validateButton);

		this.initCareers();
	}
}
