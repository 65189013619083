import $ from 'jquery';

export const META_TAGS_CONTAINER = '.meta-tags';

const getMetaTags = (container) => {

  let array = [];
  $(container).find('> div').each( (key, obj) => {

    let tag = {};
    tag['name'] = $(obj).attr('class');

    $(obj).find('> div').each( (k, o) => {
      let tagName = $(o).attr('class');
      let tagValue = $(o).text();

      tag[tagName] = tagValue;
    });

    array.push(tag);
  });

  return array;
}

export const generateSocialMediaHtml = (container) => {

  let array = getMetaTags(container);

  $(array).each( (key, obj) => {

    let link;
    if (obj.name === 'facebook-meta-tags') {
      link = 'https://www.facebook.com/dialog/feed?app_id=184683071273&quote='+obj.title+'&link='+obj.url+'&picture='+obj.image+'&source='+obj.video+'&name='+obj.title+'&caption=%20&description='+obj.description+'&redirect_uri=http%3A%2F%2Fwww.facebook.com%2F';
      $('.social-media-link.facebook').attr('href', link);
    }

    if (obj.name === 'twitter-meta-tags') {
      link = 'https://twitter.com/intent/tweet?text='+obj.description+'&url='+obj.url;
      $('.social-media-link.twitter').attr('href', link);
    }

    if (obj.name === 'generic-meta-tags') {
      link = 'mailto:?body='+obj.description+'%0a%0a'+obj.url;
      $('.social-media-link.email').attr('href', link);

      const link2 = 'https://www.linkedin.com/shareArticle?mini=true&summary='+obj.description+'&url='+obj.url;
      $('.social-media-link.linkedin').attr('href', link2);
    }
  });
}


export class SocialShare {

    constructor() {
      generateSocialMediaHtml(META_TAGS_CONTAINER);
    }

}
