
import {generateUrl} from './URLUtil';

export function pick(o, ...fields) {
    return fields.reduce((a, x) => {
        if(o.hasOwnProperty(x)) a[x] = o[x];
        return a;
    }, {});
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

function parseJSON(response) {
  return response.json();
}

export function fetchJSON(url, params = {}) {
    //this will set and send cookies
    params.credentials = params.credentials ? params.credentials : 'same-origin';
    return fetch(url, params)
      .then(checkStatus)
      .then(parseJSON);
}

export function fetchGet(url, qsParams) {
    return fetchJSON(generateUrl(url, qsParams));
}

