import $ from 'jquery';
global.jQuery = $;

const ERROR = 'error';

//  checking functions

const isRequired = (input) => {
	return	input.val().length > 0 ? true : false
}

const isNumber = ( phoneValue ) => {
	if (!isNaN(phoneValue.val())) {
		const numberRegex = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
		
		return numberRegex.test(phoneValue.val());
	}
	else{
		return	false;
	}
}

const isZip = ( zipValue ) => {

    const postalCodeFormats = [
        {country: "US", regex: "^\\d{5}([ \\-]\\d{4})?"},
        {country: "CA", regex: "^[a-zA-Z]\\d[a-zA-Z][ ]?\\d[a-zA-Z]\\d"},
        {country: "AU", regex: "^\\d{4}"},
        {country: "IN", regex: "^\\d{6}"}
    ];
    let result = false;
    postalCodeFormats.forEach((format) => {
        const formatRegex = new RegExp(format.regex);
        if(formatRegex.test(zipValue.val().trim())){
            result = true;
        }
    });

    return result;

}

const isEmail = ( email ) => {
	const mailRegex = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

	return mailRegex.test(email.val());
}

const updateFile = ( input ) => {
	input.off('change').on('change', function(event) {
		const maxSize = 51200000;
		const filename = $(this)[0].files.length ? $(this)[0].files[0].name : "";
		const fileSize = $(this)[0].files[0].size;

		if (fileSize < maxSize) {
			$(this).parent().find('span').text(filename);
			input.removeClass(ERROR);
			return true;
		}
		else{
			$(this).parent().find('span').text('');
			input.addClass(ERROR);
			return false;
		}
	});
	return input.attr('required') ? input.val() : true;
}

// Functions

const validateElement = ( element ) => {

	if (element.attr('required')) {
		isRequired(element) ? element.removeClass(ERROR) : element.addClass(ERROR)
	}

	if (element.attr('type') == 'email') {
		isEmail(element) ? element.removeClass(ERROR) : element.addClass(ERROR)
	}

	if (element.attr('type') == 'tel') {
		isNumber(element) ? element.removeClass(ERROR) : element.addClass(ERROR)
	}

	if (element.attr('name') == 'zip') {
		isZip(element) ? element.removeClass(ERROR) : element.addClass(ERROR)
	}

	if (element.attr('type') == 'file') {
	    updateFile(element) ? element.removeClass(ERROR) : element.addClass(ERROR);
	}
}

const validateAll = ( elements ) => {
	elements.each(function(index, el) {
		validateElement($(el));
	});
}

// Main

export function isValidForm( formContainer, validateButton ) {

	const inputElemnt = formContainer.find('input');

	const formElements = {
		'email'		: formContainer.find('input[type="email"]'),
		'phone'		: formContainer.find('input[type="tel"]'),
		'file'		: formContainer.find('input[type="file"]'),
		'required'	: formContainer.find('input[required]')
	}

	updateFile(formElements.file);

	validateButton.click(function( event ) {

		validateAll(inputElemnt);
		
		formContainer.keyup( function(){
			validateAll(inputElemnt)
		});
	});
}
