import $ from 'jquery';
import videojs from 'video.js';
import 'videojs-contrib-hls';

global.jQuery = $;
require('jquery.scrollto');

require('jquery-touchswipe');

import {getCurrentQueryStringParams} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/URLUtil';
import {videoInit, getVideoPlayer} from '../../../../bydeluxe/deluxe_features/features/static/features/js/Video';

const ACTIVE = 'active';

const CAROUSEL_PERIOD = 5*1000;

const getCarouselPeriod = (carouselElement) => {
    
    let period = CAROUSEL_PERIOD;
    $(carouselElement).each((i,e) => {
        period = e.dataset.slideperiod ? e.dataset.slideperiod : period;
    });
    const qsp = getCurrentQueryStringParams();
    period = qsp && qsp.carousel_period ? qsp.carousel_period : period;

    return period;
}

const findAndPlayVideoCarousel = (container) => {
    const $container = $(container);
    $(container).find('.video-js').parent().addClass('video');
    const doesAutoplay = $(container).parents('[data-autoplayvideos="True"]').length > 0;
    //if we do autoplay we want to stop the currently playing video and play the one we just got passed

    $container.parents('.carousel.large').find('.video-js').each((i, e) => {
        const player = getVideoPlayer($(e));
        player.pause();
    });
    if(doesAutoplay) {

        $container.find('.video-js').each((i,e) => {
            const player = getVideoPlayer($(e));
            /* we can't use autoplay because then all the carousel videos would play at once
                so we want to just play one video at a time, which means using the player.play()
                If the video player hasn't already loaded on FF/Edge/IE then hitting play does nothing
                so we need to wait until the player is ready.
             */
            player.ready(() => {
                player.play();
            });
        });
    }

};


const currentActiveItem = (items) => {
    const index = items.findIndex((item) => {return $(item).hasClass(ACTIVE)});
    return index;
};

const appendArrows = (carousel) => {
    const carouselWrapper = $(carousel).parent()

    if ( carouselWrapper.find('.carousel-homepage').length > 1 ) {
        const arrow = {
            left : '<div class="carousel-arrow arrow-left"><i class="ion-chevron-left"></i></div>',
            right: '<div class="carousel-arrow arrow-right"><i class="ion-chevron-right"></i></div>' 
        }

        carouselWrapper.append(arrow.right)
        carouselWrapper.prepend(arrow.left)    
    }
};

const swipeLeft = (event) => {
    const items = $(event.target).closest('.carousel.large').find('.carousel-homepage').toArray();
    if(items.length == 0) { return; };
    //find the one that is currently visible
    const index = currentActiveItem(items);
    //if the item isn't found then this will make the `0` element visible
    let next = index + 1;
    //need to make sure the next one isn't larger than the list size
    if(next >= items.length) {
        next = 0;
    }

    switchItem(items, index, next);
};

const swipeRight = (event) => {
    const items = $(event.target).closest('.carousel.large').find('.carousel-homepage').toArray();
    
    if(items.length == 0) { return; };
    //find the one that is currently visible
    const index = currentActiveItem(items);

    let previous = index -1;
    //need to make sure the next one isn't larger than the list size
    if(previous < 0) {
        previous = items.length - 1;
    }

    switchItem(items, index, previous);
};

const switchItem = (items, current, next) => {
    if(current != next) {
        $(items[current]).removeClass(ACTIVE);
        $(items[next]).addClass(ACTIVE);

        const currentSlide = $(items[current])[0];
        const nextSlide = $(items[next])[0];
    
        findAndPlayVideoCarousel(nextSlide);
    };
};


class Carousel {

    constructor(element) {
        const that = this;
        const items = $(element).find('.carousel-homepage').toArray();
        const timeOuttime = getCarouselPeriod(element);

        if(items.length == 0) { throw "no items in the carousel";}

        appendArrows(items);
        
        items.forEach((item, index) => {
            $(item).find('.video-js').each((i,e) => {
                $(e).parent().addClass('video');
                videoInit($(e));
            });
            if(index == 0) {
                $(item).addClass(ACTIVE);
                findAndPlayVideoCarousel($(item));
            } else {
                $(item).removeClass(ACTIVE);
            }
        });

        const leftAction = (event, timeOuttime) => {
			that.rotate = false;
			swipeRight(event);
			setTimeout(function(){ that.rotate = true; }, timeOuttime);
        }
        const rightAction = (event, timeOuttime) => {
        	that.rotate = false;
			swipeLeft(event);
            setTimeout(function(){ that.rotate = true; }, timeOuttime);
        }
        console.log("setting swipe");
        console.log(element);
        console.log($(element).swipe);

        $(element).find('.carousel-homepage').swipe( {
            swipeLeft:function(event, direction, distance, duration, fingerCount, fingerData) {
                rightAction(event, timeOuttime);
            },
            swipeRight:function(event, direction, distance, duration, fingerCount, fingerData) {
                leftAction(event, timeOuttime);
            },
            //swipe must be at least 25px long to be triggered
            threshold: 25
        });

		$(element).find('.arrow-left').click(function(event) {
			leftAction(event, timeOuttime) 
		});

		$(element).find('.arrow-right').click(function(event) {
			rightAction(event, timeOuttime)
    	});

        that.rotate = true;
    	
    }

    should_rotate() {
        return this.rotate;
    };

}

export class Carousels {
    constructor() {

        const isHomepageBody = $('body.homepage').length > 0;

        $(".carousel.large").toArray().forEach((element, index) => {
            const carousel = new Carousel(element);
            const carouselItems = $(element).find('.carousel-homepage');
            const auto_rotate = $(element).data('autorotate');
            if(carouselItems.length > 1 && auto_rotate) {

                const timer = setInterval(rotateFunc, getCarouselPeriod(element), element, carousel);
                function rotateFunc(element, carousel) {
                    const event = { 'target': carouselItems.first().get()};
                    if(carousel.should_rotate()) {
                        swipeLeft(event);
                    }
                    //if we're on the homepage we want to stop on the last one.
                    if(isHomepageBody && carouselItems.filter('.active').get(0) == carouselItems.last().get(0)) {
                        clearInterval(timer);
                    }
                };
            }
        });


    }
}
