import $ from 'jquery';
global.$ = $;
require('easy-autocomplete');

import {addInputClear} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/InputUtil';

export class CareersAutocomplete {
    constructor() {

        $('#career-landing-search-form input[name=search_term]').each((i,e) => {
            const $input = $(e);

            addInputClear($input);

            const siteId = !!$input.data('siteid') ? $input.data('siteid') : '';
            const url = `/api/careers/search/?site_id=${siteId}&title=`;

            $input.easyAutocomplete({
                url: function(phrase) {
                    if (phrase !== "") {
                        return url + phrase;
                    }
                },
                listLocation: "hits",
                getValue: "title",
                requestDelay: 200,
                list: {
                    onChooseEvent: function() {
                        $('#career-landing-search-form button.submit').trigger('click');
                    }
                }
            });
        });
    }
}
