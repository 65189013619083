import qs from 'qs';

export const getCurrentHostAndPath = () => {
    return `//${location.host}${location.pathname}`;
};

export const generateUrl = (url, qsParams) => {
    return qsParams ? url + '?' + qs.stringify(qsParams, { indices: false }) : url;
};

export const parseUrlForQueryStringParams = (url) => {
    const index = url.indexOf('?');
    const queryString = index > -1 && url.length > 1 ? url.substr(index + 1) : "";
    return qs.parse(queryString, { indices: false });
}

export const getCurrentQueryStringParams = () => {
    return parseUrlForQueryStringParams(window.location.search);
};

