import $ from 'jquery';
global.jQuery = $;

import {videoInit} from '../../../../bydeluxe/deluxe_features/features/static/features/js/Video';


export class VideoPublisher {
    constructor() {
        $(".video-wrapper").each((i,e) => {
            videoInit($(e).find('.video-js'));
        });
    }
}
