import $ from 'jquery';
global.jQuery = $;
require('../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/vendors/deluxe-jquery-pjax');
$.pjax.defaults.scrollTo = false;

import {pushPjaxState} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/PushStateUtil';
import {sendAnalytic} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/AnalyticsUtil';

import {Video, disposeOfPlayer} from '../../../../../deluxe_permalinks/permalinks/static/permalinks/js/Video';

import {META_TAGS_CONTAINER, generateSocialMediaHtml} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/SocialShare';

const pjaxContainerId = 'permalink-pjax-container';
const pjaxContainerSelector = `#${pjaxContainerId}`
const permalinkOverlayWrapper = '.permalink-overlay-wrapper';
const dataPermalinkPjaxSelector = 'a[data-permalink-pjax]';
const PERMALINK_ITEM_SELECTOR = '.permalink-item';
const CLOSED_CLASS = 'closed';

const dataGridCmsPluginId = 'data-gridcmspluginid';
const dataGridItemCmsPluginId = 'data-griditemcmspluginid';

const setFixedView = () => {
  $('html, body').css({
    'position': 'relative',
    'overflow': 'hidden'
  });
  $('body').css({'height': '100%'});
}

const resetFixedView = () => {
  $('html, body').removeAttr('style');
}

const closeOverlay = (pushStateFunc = () => {}) => {
    $(permalinkOverlayWrapper).each((i,wrapper) => {
        disposeOfPlayer();
        resetFixedView();
        $(wrapper).find(PERMALINK_ITEM_SELECTOR).each((i,e) => { $(e).addClass(CLOSED_CLASS); });
        $(wrapper).hide();
        pushStateFunc();
    });
}


const handleCloseEvent = (event, pushStateContainerSelector) => {
    event.preventDefault();
    closeOverlay(() => {
        pushPjaxState($(event.target).parent().attr('href'), null, pushStateContainerSelector, pjaxContainerSelector);
    });
}

const updateNavigationArrowLinks = (event, overlay) => {
    //set the previous and next link
    if(event && event.relatedTarget) {
        const gridPluginId = event.relatedTarget.dataset.gridcmspluginid;
        const gridItemCmsPluginId = event.relatedTarget.dataset.griditemcmspluginid;
        const gridItemSelector = `.grid[data-cmspluginid="${gridPluginId}"] .grid-item[data-id="${gridItemCmsPluginId}"]`;
        const gridItem = $(gridItemSelector);
        const prev = $(gridItem).prevAll().find(dataPermalinkPjaxSelector).last().get();
        const next = $(gridItem).nextAll().find(dataPermalinkPjaxSelector).first().get();
        const $overlay = $(overlay);

        const updateArrow = (selector, atags) => {
            if (atags && atags.length > 0) {
                const atag = atags[0];
                $overlay.find(selector).each((i,e) => {
                    //we do this instead of .show so that the css can still hid it for mobile
                    $(e).attr('style', '');
                    $(e).attr('href', $(atag).attr('href'));
                    $(e).attr(dataGridCmsPluginId, $(atag).attr(dataGridCmsPluginId));
                    $(e).attr(dataGridItemCmsPluginId, $(atag).attr(dataGridItemCmsPluginId));
                });

            } else {
                $overlay.find(selector).each((i,e) => { $(e).hide(); });
            }
        };

        updateArrow('a.left-arrow', prev);
        updateArrow('a.right-arrow', next);
    }
};

export const updatePermalinkCloseUrl = () => {
    $(".permalink-overlay .close-overlay > a").each((i,e) => {
        const url = window.location.pathname + window.location.search;
        $(e).attr('href', url);
    });
};

export class PermalinkOverlay {
    constructor() {
        $('body.permalink').each((i,video) => { new Video(); });
        $(`${permalinkOverlayWrapper} .header .close-overlay a`).each((i,e) => {
            $(e).click((event) => {
                handleCloseEvent(event, pjaxContainerSelector);
            });
        });

        $('.grid').each((i,grid) => {
            $(grid).find(dataPermalinkPjaxSelector).each((i, e) => {
                $(e).attr(dataGridCmsPluginId, grid.dataset.cmspluginid);
                const gridItem = $(e).parents('.grid-item').first()[0];
                $(e).attr(dataGridItemCmsPluginId, gridItem.dataset.id);
            });
        });

        $(document).pjax(dataPermalinkPjaxSelector, pjaxContainerSelector);
        $(document).on('pjax:end', (event, options) => {
            if(event.target.id && event.target.id == pjaxContainerId
                && $(event.target).find(PERMALINK_ITEM_SELECTOR).length > 0
                && $(event.target).find(`${PERMALINK_ITEM_SELECTOR}.${CLOSED_CLASS}`).length < 1) {

                $(permalinkOverlayWrapper).each((i,overlay) => {
                    const $overlay = $(overlay);
                    setFixedView();
                    updateNavigationArrowLinks(event, $overlay);

                    disposeOfPlayer();
                    new Video();

                    generateSocialMediaHtml(META_TAGS_CONTAINER);

                    $overlay.show();
                    sendAnalytic({ 'hitType': 'pageview', 'location': event.target.baseURI});
                });
            } else {
                closeOverlay();
            }
        });

    }
}
