
import {fetchGet, pick} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/BaseAPIUtil';

export function getCareers(data) {
    //here we should filter all the unneeded parameters out.
    const params = pick(data, 
                        'page', 
                        'size', 
                        'search_term',
                        'sort', 
                        'title',
                        'site_id',
                        'company',
                        'user_country_code',
                        'country_code',
                        'state',
                        'city',
                        'employment_types',
                        'departments',
                        'divisions',
                        'exclude');
    return fetchGet('/api/careers/search/', params);
}




