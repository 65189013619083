import $ from 'jquery';

import {isPhoneWidth} from '../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/WindowUtil';

const MAX_OFFSET = 141;
const SUB_MENU_ACTIVE_CLASS = 'active';


export const hasCarouselHeader = () => {
    let hasCarouselHeader = false;
    $('body .carousel').first().each((i,e) => {
        hasCarouselHeader = isCarouselHeader(e);
    });
    return hasCarouselHeader;

};

export const isCarouselHeader = (carousel) => {
    let isCarouselHeader = false;
    const offset = $(carousel).offset();
    if (offset.top < MAX_OFFSET) {
        isCarouselHeader = true;
    }
    return isCarouselHeader;
};

export const hasVideoPublisherHeader = () => {
    let isVideoPublisherHeader = false;
    $(".video-wrapper").first().each((i,e) => {
        if ($(e).offset().top < MAX_OFFSET) {
            isVideoPublisherHeader = true;
        }
    });
    return isVideoPublisherHeader;
};

export const hasCareerSearchLandingPromotionHeader =  () => {
    const offset = $(".career-search-landing-promotion-wrapper").offset();
    return !!offset && offset.top < MAX_OFFSET;
};

export const closeAllSubMenus = (selector) => {
    $(selector).removeClass(SUB_MENU_ACTIVE_CLASS);
};

export const activateSubMenu = (menuItem, subMenuSelector) => {
    const $menuItem = $(menuItem);

    if(!$menuItem.hasClass(SUB_MENU_ACTIVE_CLASS)) {
        closeAllSubMenus(subMenuSelector);
        $menuItem.addClass(SUB_MENU_ACTIVE_CLASS);
    }
};

export const addHoverClasses = (subMenuSelector) => {

    //this code adds our class on hover
    $(subMenuSelector).each((i,e) => {
        $(e).mouseover((event) => {
            //DXCAREERS-128 - on android click and hover events are both sent.
            if(!isPhoneWidth()) {
                //need to check that it's not already active
                activateSubMenu(event.currentTarget, subMenuSelector);
            }
        });

        $(e).mouseout((event) => {
            //DXCAREERS-128 - click and hover events are both sent.
            if(!isPhoneWidth()) {
                //BYD-1171 here we check if the mouseout event is just going to the sub-menu element that is a child,
                //if it is then we do nothing.  It was found that the mouseout event was being fired while moving down
                //from the title to the sub-menu
                const isChild = Boolean($(e).has(event.relatedTarget).length);
                const isDebugSpan = Boolean($(event.relatedTarget).parents(".cms-tooltip").length);
                if(!isChild && !isDebugSpan) {
                    closeAllSubMenus(subMenuSelector);
                }
            }
        });

        $(e).on('click', (event) => {
            //DXCAREERS-194 fix for items inside sub menu being clicked.
            const $subMenuItem = $(event.currentTarget);
            if($(event.target).parent().get(0) === $subMenuItem.get(0)) {
                //it's a direct parent so we can assume we meant to trigger the open/close
                //of the sub menu
                if($subMenuItem.hasClass(SUB_MENU_ACTIVE_CLASS)) {
                    closeAllSubMenus(subMenuSelector);
                } else {
                    activateSubMenu($subMenuItem, subMenuSelector);
                }
            }
        });

    });
};


