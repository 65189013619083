// Js controller for the about section news

import $ from 'jquery';
import {SearchUtil} from '../../../../methodstudios_menus/static/methodstudios_menus/js/SearchUtil';

const apiNewsUrl = '/api/news/search/';
const maxAboutNewsResults = false;

const numberOfNewsItems = 9;

export class AboutNews {
    constructor() {
        if (!$('body.about, body.careers-landing').length) {
            return;
        }
        this.newsContent = $('.news-grid-content #news-grid-container');
        this.newsResults = false;
        this.newsGrid = false;
        this.initNewsSection();

    }

    /**
     * Creates grid container and calls search api to build results cards HTML
     */
    initNewsSection() {
        // js hack for the news mobile action link, to differentiate it from others
        const newsActionLink = $('.news-grid-content').prev('.action-link');
        newsActionLink.addClass('mobile-title');

        if (!this.newsContent.find('.grid-container .grid').length) {
            this.newsContent.append(`<div class="grid-component">
                <div class="grid-container">
                    <div class="grid"></div>
                </div>
            </div>`);
        }

        const setMinHeight = () => {
            const width = window.innerWidth;
            const elementsPerRow = width < 992 ? 1.0 : 3.0;
            let minHeight = 321.594; /* the single row height for the mobile version */
            if(elementsPerRow > 1) {
                /* we use jquery innerWidth because it excludes the scroll bar */
                const widthPerElement = $(window).innerWidth() / elementsPerRow;
                const ratio = 0.67; /* from the css 67% */
                const rowHeight =  widthPerElement * ratio;
                minHeight = 3 * rowHeight; /* # of rows * height of row */
            }
            this.newsContent.css({ minHeight: minHeight + 'px'});
        };

        setMinHeight();
        $(window).resize(() => {
            setMinHeight();
        });

        this.newsGrid = this.newsContent.find('.grid-container .grid');
        this.getNewsResults()
            .then(() => {
                // Avoid collision of search promises
                if (!this.isFetching) {
                    const results = this.newsResults;
                    if (results.total > 0) {
                        const cards = this.buildNewsCards(results);
                        this.newsGrid.append(cards);
                        this.setSlick();

                        $(window).resize(() => {
                            if (!this.newsGrid.hasClass('slick-initialized')) {
                                this.setSlick();
                            }
                        });
                    }
                }
            });
    }

    /**
     * @param results
     * @returns {string}
     * Constructs HTML for the news cards
     */
    buildNewsCards(results) {
        const hits = maxAboutNewsResults ? results.hits.slice(0, maxAboutNewsResults) : results.hits;
        let cards = '';

        Object.keys(hits).forEach((hitindex) => {
            const hit = hits[hitindex];
            cards += hit.html;
        });

        return cards;
    }

    /**
     * Initializes slick carousel for mobile devices
     */
    setSlick() {
        this.newsGrid.on('init', function() {
            $(this).find('.slick-track').css({ marginLeft: '-70px' });
            $(this).find('.slick-track .content p').css({ marginLeft: '70px' });
        });

        this.newsGrid.slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay: false,
            useTransform: false,
            variableWidth: true,
            arrows: true,
            draggable: false,
            prevArrow: '<i class="ion-arrow-left-c slick-prev" data-pack="default" data-tags=""></i>',
            nextArrow: '<i class="ion-arrow-right-c slick-next" data-pack="default" data-tags=""></i>',
            accessibility: true,
            responsive: [
            {
                breakpoint: 991,
                settings: 'unslick'
            }]
        });

        this.newsGrid.on('destroy', function() {
            $(this).find('.grid-item').css({ left: 'inherit' });
        });
    }

    /**
     * @returns {Promise<Response>}
     * Fetches api to get the news results
     */
    getNewsResults() {
        this.isFetching = true;
        return SearchUtil.fetchJSON(apiNewsUrl, {sort: '-publish_date', size: numberOfNewsItems})
            .then((data) => {
                this.newsResults = data;
                this.isFetching = false;
            });
    }
}
