const IOS_CLASS = 'ios';

export const isIE11 = () => {
   return !!window.MSInputMethodContext && !!document.documentMode;
};

//this excludes Edge, as that is no longer internet explorer.
export const isInternetExplorer = () => {
    //https://msdn.microsoft.com/en-us/library/ms537509(v=vs.85).aspx
    //Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject
    return (navigator.appName == 'Microsoft Internet Explorer') || isIE11();
};

const isIOS = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isIOS;
};

export const addBrowserBodyClass = () => {
    if(isIOS()) {
        $("body").addClass(IOS_CLASS);
        const version = iOSVersion();
        if(version && version < 13) {
            $("body").addClass(IOS_CLASS + '-12-minus')
        }
    }
};

export const iOSVersion = () => {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        var matches = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
        return matches && matches.length > 0 && matches[1];
    }
}