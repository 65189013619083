import $ from 'jquery';
global.jQuery = $;
require('../../../../../deluxe_common/deluxe_common/static/deluxe_common/js/vendors/deluxe-jquery-pjax');

import {generateUrl} from './URLUtil';

const createState = (url, qsParams, containerSelector) => {
    const uniqueId = () => { //same function used in pjax...
        return (new Date).getTime()
    }

    const urlWithQueryString = generateUrl(url, qsParams);
    console.log(`url for pushState : ${url}`);
    const state = {
      id: uniqueId(),
      url: urlWithQueryString,
      title: document.title,
      container: containerSelector,
      nonPjax: true,
      fragment: undefined,
      timeout: 650
    };


    console.log('push state');
    console.log(state);
    return state;
};

export const pushState = (url, qsParams, containerSelector) => {
    const state = createState(url, qsParams, containerSelector);
    window.history.pushState(state, state.title, state.url);
    return state;
};

export const replaceState = (url, qsParams, containerSelector) => {
    const state = createState(url, qsParams, containerSelector);
    window.history.replaceState(state, state.title, state.url);
    return state;
};

/*
pjax.state = {
      id: options.id || uniqueId(),
      url: container.url,
      title: container.title,
      container: context.selector,
      fragment: options.fragment,
      timeout: options.timeout
    }

    if (options.push || options.replace) {
      window.history.replaceState(pjax.state, container.title, container.url)
    }
*/

export const pushPjaxState = (url, qsParams, containerSelector, pjaxContent) => {
    const state = pushState(url, qsParams, containerSelector);

    const cacheValue = [containerSelector, $(containerSelector)];
    $.pjax.state = state;
    $.pjax.cachePush(state.id, cacheValue);
};