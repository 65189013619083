import $ from 'jquery';

import {hasCarouselHeader, hasVideoPublisherHeader, hasCareerSearchLandingPromotionHeader} from '../../../../bydeluxe/deluxe_menus/deluxe_menus/static/deluxe_menus/js/MenuUtil.js';
import {addBrowserBodyClass} from '../../../../bydeluxe/deluxe_common/deluxe_common/static/deluxe_common/js/BrowserUtil.js';

import debounce from 'throttle-debounce/debounce';

const OPEN = 'opened';
const CLOSE = 'closed';
const fadeTime = 500;
const cssTransition = (fadeTime / 1000);
const transitionType = 'ease-in';

const SCROLLING = 'scrolling';

const SUB_MENU_FADE_TIME = 250;
const MAX_OFFSET = 141;

export function createOverlayFooter(overlayElement) {
    if (!overlayElement.find('.footer-outer-wrapper').length) {
        const footer = $('.footer-wrapper').first().clone();
        $('<div class="footer-outer-wrapper"><div class="outer-wrapper"></div></div>').appendTo(overlayElement);
        overlayElement.find('.footer-outer-wrapper .outer-wrapper').append(footer);
    }
}

export function getScrollbarSize() {
    $('<div class="rulerouter"></div>')
        .css({position: 'absolute', height: '100px', visibility: 'hidden', left: 0, right: 0})
        .appendTo('body');
    const outerWidth = $('.rulerouter').width();
    $('<div class="rulerinner"></div>')
        .css({width: '100%'})
        .appendTo('.rulerouter');
    $('.rulerouter').css({overflow: 'scroll'});
    const innerWidth = $('.rulerinner').width();
    const scrollWidth = outerWidth - innerWidth;
    $('.rulerouter').remove();
    return scrollWidth;
}

export function scrollbarExists(element = false) {
    if (!element) {
        return $(document).height() > $(window).height();
    } else {
        return element.get(0).scrollHeight > element.get(0).clientHeight;
    }
}

export { OPEN, CLOSE, fadeTime, transitionType, cssTransition };

export const isStaticPosition = (overlayWrapper) => {
    const position = $(overlayWrapper).css("position");
    return position === 'static';
};
export const checkAndScrollIfPositionStatic = (overlayWrapper) => {
    if(isStaticPosition(overlayWrapper)) {
        window.scrollTo(0, 0);
    }
};

export const MARGIN_RIGHT_ITEMS = [
    "body",
    "body.homepage .carousel.large .carousel-homepage.active",
    "body.homepage .carousel.large .carousel-arrow.arrow-right",
    ".main-menu-wrapper .footer-outer-wrapper .outer-wrapper",
    ".search-overlay-wrapper .footer-outer-wrapper .outer-wrapper"
];

const MAIN_MENU_PADDING_LEFT_ITEMS = [".main-menu-wrapper"];

export const openMenuAddMarginsAndPadding = (leftItems, addLeft, rightItems, addRight) => {

    if(addRight) {
        $('body').addClass('on-overlay');
        rightItems.forEach((e) => $(e).css({ marginRight: addRight }));
        $("body.homepage .carousel .carousel-nav").css({ marginLeft: `calc(${addRight} * -0.09)` });
    }

    if(addLeft) {
        leftItems.forEach((e) => $(e).css({ paddingLeft: addLeft }));
    }

    //there is a special case where if the page doesn't have a scroll bar then the footer doesn't line up correctly.
    if(!addRight) {
        /* fix for page having no scrollbar. */
        //need to check that we didn't just switch from a different
        if(!$("body.on-overlay").length) {
            $(".footer-outer-wrapper").css({paddingTop: "21px"});
        }
    }
};

const triggerCloseMenuEvent = () => {
    //DXCAREERS-244 - closing menu on homepage on iOS doesn't show carousel image
    $(window).trigger('mainmenu:close');
};

export const closeMenuRemoveMarginsAndPadding = (paddingLeftItems, marginRightItems) => {

    marginRightItems.forEach((e) => $(e).css({ marginRight: '' }));
    paddingLeftItems.forEach((e) => $(e).css({ paddingLeft: '' }));
    $("body.homepage .carousel .carousel-nav").css({ marginLeft: '' });
    triggerCloseMenuEvent();
};

const openMainMenuAddMarginsAndPadding = (addLeft, addRight) => {


    openMenuAddMarginsAndPadding(
        MAIN_MENU_PADDING_LEFT_ITEMS,
        addLeft,
        MARGIN_RIGHT_ITEMS,
        addRight
    );

};

const closeMainMenuRemoveMarginsAndPadding = () => {
    closeMenuRemoveMarginsAndPadding(MAIN_MENU_PADDING_LEFT_ITEMS, MARGIN_RIGHT_ITEMS);
};

const CONTROL_SEARCH_SELECTOR = '.control-search';
const MAIN_MENU_SELECTOR = '.main-menu-wrapper';

export const addOrRemoveScrollingClass = () => {
    const controlSearch = $(CONTROL_SEARCH_SELECTOR);
    const mainMenu = $(MAIN_MENU_SELECTOR);

    if ($(document).scrollTop() === 0) {
        mainMenu.removeClass(SCROLLING);
        if ($('.content-placeholder').hasClass('white-header')) {
            controlSearch.addClass('dark');
        } else {
            controlSearch.removeClass('dark');
        }
    } else {
        mainMenu.addClass(SCROLLING);
        controlSearch.addClass('dark');
    }
}

export const hasHeaderFullWidthImage =  () => {
    const offset = $(".header-full-width-image-wrapper").offset();
    return !!offset && offset.top < MAX_OFFSET;
};

function isLeaf($element) {
    return $element.parent().hasClass('leaf');
}

export class Menu {
    constructor() {
        this.mainMenu = $('.main-menu');
        this.footer = $('footer');
        this.controlSearch = $(CONTROL_SEARCH_SELECTOR);
        this.search = $('.control-search .search-toggle');
        this.hamburger = $('.control-search .open-toggle');
        this.contentPlaceHolder = $('.content-placeholder,.error-page');
        this.isMobilePage = this.checkMobile();
        this.mainMenuWrapper = $(MAIN_MENU_SELECTOR);
        this.isMenuOpen = false;
        this.scrollSize = getScrollbarSize();
        this.onResize();
        this.initMainMenu();
        this.checkWhiteHeader();
        createOverlayFooter(this.mainMenuWrapper);
        addBrowserBodyClass();
    }

    /**
     * Adds a listener for screen resizing or rotating to change menu behaviour and layout on resize
     */
    onResize() {
        $(window)
            .off('resize.menu.window')
            .on('resize.menu.window', () => {
                const thisWasMobile = this.isMobilePage;
                this.isMobilePage = this.checkMobile();

                // Check if screen changed mobile <--> desktop
                if (thisWasMobile !== this.isMobilePage) {
                    this.checkWhiteHeader();

                    if (this.isMenuOpen) {
                        this.resetMenuLayout();
                    }
                }
            });
    }


    /**
     * Initialises menu options listeners
     */
    initMainMenu() {
        const that = this;

        this.mainMenuWrapper.css({ transition: `background ${cssTransition}s ${transitionType}`});

        this.hamburger
            .off('click.menu.toggle')
            .on('click.menu.toggle', debounce(fadeTime, true, () => {
                if (this.isMenuOpen) {
                    this.isMenuOpen = false;
                    this.setMenuLayout(CLOSE);
                } else {
                    this.isMenuOpen = true;
                    this.setMenuLayout(OPEN);
                }
            }));

        this.search
            .off('click.search.onmenu')
            .on('click.search.onmenu', () => {
                if (this.isMenuOpen) {
                    this.isMenuOpen = false;
                    this.mainMenuWrapper.data('closedbysearch', true);
                    $('.search-overlay-wrapper').addClass('opening');
                }
            });

        this.mainMenu
            .find('> ul > li > a')
            .off('click.menu.option')
            .on('click.menu.option', function (e) {

                if(isLeaf($(event.currentTarget))) {
                    return;
                }

                e.preventDefault();

                // Prevents click on main menu option from leaving open submenu
                if (!that.isMobilePage) {
                    return;
                }

                const option = $(this);
                const subMenu = option.next('ul');

                if($(subMenu).hasClass('active')) {
                    $(subMenu).addClass('closing');
                    setTimeout(() => {
                        $(subMenu).removeClass('closing');
                    }, SUB_MENU_FADE_TIME);
                }
                $.merge(option, subMenu).toggleClass('active');
            });

        this.mainMenu
            .find('> ul > li > a')
            .off('click.menu.category auxclick.menu.category')
            .on('click.menu.category auxclick.menu.category', (event) => {
                if (!this.isMobilePage && !isLeaf($(event.currentTarget))) {
                    event.preventDefault();
                }
            });

        addOrRemoveScrollingClass();
        $(document)
            .off('scroll.body')
            .on('scroll.body', () => {
                addOrRemoveScrollingClass();
            });
    }

    /**
     * @param {string} action
     * Set menu and backdrop layout for desktop and mobile
     */
    setMenuLayout(action) {
        this.isMobilePage = this.checkMobile();
        const menuAndSearch = $.merge(this.controlSearch, this.mainMenuWrapper);
        const footerWrapper = this.mainMenuWrapper.find('.footer-outer-wrapper');

        if (action === OPEN) {
            this.mainMenuWrapper.removeClass(SCROLLING);

            menuAndSearch
                .removeClass('closed')
                .addClass('opened');

            if (this.isMobilePage) {
                $('body')
                    .addClass('on-overlay');
                this.fadeInMenu(() => {});
            } else {

                //this is a hack that is needed for Edge 16. because Edge 16 draws the bullets on a list that is flex displayed
                this.mainMenuWrapper.find(".main-menu > ul").css({ opacity: 0, display: 'block'});
                setTimeout(
                    () => {this.mainMenuWrapper.find(".main-menu > ul").css({ opacity: 1, display: 'flex'})},
                    fadeTime / 2.0
                );
                //end Edge hack

                const addRight = scrollbarExists() ? this.scrollSize + 'px' : '';
                const addLeft = scrollbarExists(this.mainMenuWrapper) ? this.scrollSize + 'px' : '';
                openMainMenuAddMarginsAndPadding(addLeft, addRight);
            }
            checkAndScrollIfPositionStatic(this.mainMenuWrapper);
        } else  {
            this.mainMenu
                .find('> ul > li > ul')
                .removeClass('active')
                .end()
                .find('> ul > li > a')
                .removeClass('active');

            if (this.isMobilePage) {
                $('body').removeClass('on-overlay');
                this.mainMenuWrapper.addClass('closing');
                triggerCloseMenuEvent();
            }

            this.fadeOutMenu(() => {
                if (!this.isMobilePage) {
                    $('body').removeClass('on-overlay')
                    closeMainMenuRemoveMarginsAndPadding();
                }

                menuAndSearch
                    .removeClass('opened closing')
                    .addClass('closed');
            });
        }
    }

    fadeInMenu(callback) {
        const footerWrapper = this.mainMenuWrapper.find('.footer-outer-wrapper');

        this.mainMenu
            .css({ opacity: 0, display: 'block' })
            .animate({ opacity: 1 }, fadeTime, () => { this.mainMenu.removeAttr('style'); });
        footerWrapper
            .css({ opacity: 0, display: 'block' })
            .animate({ opacity: 1 }, fadeTime, () => {
                footerWrapper.css({ opacity:'', display:''});
                callback();
            });
    }

    fadeOutMenu(callback) {

        addOrRemoveScrollingClass();
        const footerWrapper = this.mainMenuWrapper.find('.footer-outer-wrapper');

        this.mainMenuWrapper.addClass('closing');
        this.mainMenu
            .animate({ opacity: 0 }, fadeTime, () => { this.mainMenu.removeAttr('style'); });

        footerWrapper
            .animate({ opacity: 0 }, fadeTime, () => {
                this.mainMenuWrapper.removeClass('closing');
                footerWrapper.css({ opacity:''});
                callback();
            });
    }

    /**
     * Reconfigure menu and backdrop layout in case of mobile <--> desktop change
     */
    resetMenuLayout() {
        this.mainMenu
            .find('> ul > li > ul')
            .removeClass('active')
            .end()
            .find('> ul > li > a')
            .removeClass('active');

        if (this.isMobilePage) {
            // Changes from desktop to mobile
            $('body').removeClass('on-overlay');
        } else {
            $('body').addClass('on-overlay');
        }
    }

    /**
     * Checks if there's a carousel at the top of page to set main menu color
     */
    checkWhiteHeader() {
        // Check pages that may not have a carousel at the beginning to darken main menu and add margin to content
        this.isMobilePage = this.checkMobile();
        const pageCarousels = $('body .carousel');


        let isWhiteHeader =
            !hasCarouselHeader() &&
            !hasVideoPublisherHeader() &&
            !hasCareerSearchLandingPromotionHeader() &&
            !hasHeaderFullWidthImage();
        if(isWhiteHeader) {
            isWhiteHeader = $('.page-name-wrapper').length === 0;
        }

        if (isWhiteHeader) {
            $(".youtube-embed-wrapper")
                .first()
                .filter(
                    (i,e) => $(e).offset().top < MAX_OFFSET
                ).addClass('hero');
            $('.control-search').addClass('dark');
            $('.content-placeholder').addClass('white-header');
        }
    }

    checkMobile() {
        const mediaProperty = window.getComputedStyle(document.body, ':after');
        const screen = mediaProperty.content;
        return screen !== '"desktop"';
    }
}

